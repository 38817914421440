import Cookies, { CookieAttributes } from "js-cookie";

export const getCookieName = (name: string): string | undefined => {
  if (!!name) {
    return Cookies.get(name);
  }
};
export const getCookie = () => {
  //! `Cookies.get` will return `undefined` in environments where `document`
  //! doesn't exist (eg. during SSR). `@types/js-cookie` doesn't cater for this
  //! so default to empty object if `undefined`
  return Cookies.get() || {};
};

export const setCookie = (
  name: string,
  value: string | object,
  options?: CookieAttributes,
) => {
  return Cookies.set(name, value, options);
};

export const getJSON = (name: string) => {
  return Cookies.getJSON(name);
};
