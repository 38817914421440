import { TypeSmart, ThemeSelectors } from "@at/design-system-foundation";
import { BREAKPOINTS } from "@at/window-utils/lib/responsive-utils/match-media.util";
import styled, { createGlobalStyle, css } from "styled-components";

import { AdvertNavigationPosition } from "../components/navigation/advert-navigation";
import { PrintStyleSheet } from "../components/seller/advert-seller-details/location/location.styles";

export const GlobalProductPageStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => ThemeSelectors.getLegacyWhite(theme)};
    color: ${({ theme }) => ThemeSelectors.getLegacyCopyLightColor(theme)};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.is-fixed {
      height: 100vh;
      overflow: hidden;
    }
  }

  @media print {
    @page {
        margin: 0;
    }
    body {
        margin: 0;
    }
}

  nav#atds-navigation {
    ${PrintStyleSheet}
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => ThemeSelectors.getActionColor(theme)};
    &:hover {
      color: ${({ theme }) => ThemeSelectors.getActionHoverColor(theme)};
    }
  }

  ul,
  ol,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => ThemeSelectors.getLegacyBlue(theme)};
  }

  button {
    cursor: pointer;
  }

  /* Override Header z-index: 200 (who knows why its 200) so modal window is highest on z-index */
  header.atc-foundation-header {
    z-index: 2;
  }

  /* CSS Objects */
  .full-width {
    width: 100%;
  }
`;

export const AdvertContainerStyles = styled.div<BreakoutStyles>`
  display: block;
  width: 100%;
  max-width: ${BREAKPOINTS.EXWIDE}px;
  margin: 0 auto;

  &::after {
    clear: both;
    content: "";
    display: table;
  }

  ${({ theme, nav }) => {
    const gridUnit = ThemeSelectors.getGridUnit(theme);

    if (nav) {
      return css`
        padding: 0 calc(${gridUnit} * 2);

        @media (min-width: ${BREAKPOINTS.MEDIUM}px) {
          padding: 0;
        }

        @media (min-width: ${BREAKPOINTS.EXWIDE}px) {
          padding: 0 calc(${gridUnit} * 3);
        }
      `;
    }

    return css`
      padding: 0 calc(${gridUnit} * 2) calc(${gridUnit} * 2);

      @media (min-width: ${BREAKPOINTS.EXWIDE}px) {
        padding: 0 calc(${gridUnit} * 3) calc(${gridUnit} * 3);
      }
    `;
  }}

  ${({ breakout }) => {
    if (!breakout) return;

    return css`
      @media (max-width: ${BREAKPOINTS.MEDIUM - 1}px) {
        padding-left: 0;
        padding-right: 0;
      }
    `;
  }}

  .vehicle-strip {
    padding-top: calc(${({ theme }) => ThemeSelectors.getGridUnit(theme)} * 3);
  }
`;

export const OnePagePadding = styled.div`
  margin-top: 24px;
`;

export interface BreakoutStyles {
  nav?: boolean;
  breakout?: boolean;
  position?: AdvertNavigationPosition;
}

export const AdvertBreakOutSection = styled.div<BreakoutStyles>`
  ${({ theme, nav }) => {
    if (nav) {
      return css`
        padding: 0;

        @media (min-width: ${BREAKPOINTS.WIDE}px) {
          background-color: transparent;
        }
      `;
    }

    return css`
      margin: calc(${ThemeSelectors.getGridUnit(theme)} * 3) 0;
    `;
  }}

  ${({ position }) => {
    if (position !== AdvertNavigationPosition.BOTTOM) return;

    return css`
      @media (min-width: ${BREAKPOINTS.WIDE}px) {
        display: none;
      }
    `;
  }}

  ${AdvertContainerStyles} {
    ${({ nav, theme }) => {
      if (nav) return;

      return css`
        padding: calc(${ThemeSelectors.getGridUnit(theme)} * 2)
          ${ThemeSelectors.getGridUnit(theme)};
      `;
    }}

    @media (min-width: ${BREAKPOINTS.EXWIDE}px) {
      padding: 0;
    }
  }
`;

export const AdvertArticleContainer = styled.article`
  width: 100%;
  display: block;

  @media (min-width: 991px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
  }
`;

export const AdvertArticleSplitContainer = styled.article`
  width: 100%;
  display: block;
  padding-top: ${({ theme }) => ThemeSelectors.getGroupFiveSpacing(theme)};
  margin-bottom: ${({ theme }) => ThemeSelectors.getRelatedOneSpacing(theme)};
  flex-direction: column;

  @media (min-width: 991px) {
    margin-bottom: 80px;
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    column-gap: 3em;

    & > div {
      width: 48%;
      border-bottom: 1px solid ${({ theme }) => ThemeSelectors.getGrey(theme)};
      padding-top: 12px;

      &:first-child {
        border-top: 1px solid ${({ theme }) => ThemeSelectors.getGrey(theme)};
      }

      &:nth-child(2) {
        border-top: 1px solid ${({ theme }) => ThemeSelectors.getGrey(theme)};
      }
    }
  }
`;

export const AdvertTitleSection = styled.section`
  ${({ theme }) => {
    return css`
      margin-top: 0;

      p:last-of-type {
        color: ${ThemeSelectors.getLegacyBlue(theme)};
      }
    `;
  }}
`;

export const AvailableToReserveBadge = styled(TypeSmart).attrs({
  weight: 500,
  as: "span",
})`
  display: inline-block;
  padding: 4px 12px;
  margin-bottom: 8px;
  border-radius: 20px;
  background-color: #d0f2df;
  color: ${({ theme }) => ThemeSelectors.getTitleColor(theme)};

  @media print {
    padding: 0;
  }
`;
