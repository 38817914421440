import React from "react";

import {
  ATTracking,
  CURRENT_PAGE_ENTITY_VERSION,
} from "@at/consumer-web-snowplow";
import { ATDSGlobalStyles } from "@at/design-system-foundation";

import { useAppContext } from "@at/sauron-platform-core";
import { initNewTracker } from "@at/track-core";

import { Routes } from "./routes";

const App = () => {
  const {
    isDesktop,
    ssrMode,
    config: { env, appName },
  } = useAppContext();

  initNewTracker({
    env,
    appName,
    platform: isDesktop() ? "web" : "mob",
    ssrMode,
  });

  return (
    <>
      <ATTracking
        pageEntity={{ SCHEMA_KEY: CURRENT_PAGE_ENTITY_VERSION, name: appName }}
        skipTracking={ssrMode}
      >
        <ATDSGlobalStyles />
        <Routes />
      </ATTracking>
    </>
  );
};

export default App;
