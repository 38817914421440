import { LeaseContractType } from "../../../__generated__/graphql-types";
import { LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms } from "../../../connected-components/hoc/query/__generated__/pdp-lease-offers.query";
import { ProductPageQuery_search_Search_advert_Advert } from "../../../connected-components/hoc/query/__generated__/pdp-page-data.query";
import { LeasingActions } from "../actions/leasing.action.keys";
import { initState } from "../state/init-state";
import {
  ELeasingBusinessType,
  LeasingContextAction,
  LeasingModalState,
  LeasingState,
  ProductPageStockFilters,
} from "../types/leasing.types";

export const leasingReducer = (
  state: LeasingState,
  { type, payload }: LeasingContextAction,
): LeasingState => {
  switch (type) {
    case LeasingActions.SET_LEASING_CONTRACT_TYPE: {
      return {
        ...state,
        leaseContractType: payload as LeaseContractType,
      };
    }
    case LeasingActions.SET_SELECTED_LEASE_OFFER: {
      return {
        ...state,
        selectedLeaseOffer:
          payload as LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms,
      };
    }
    case LeasingActions.SET_SELECTED_STOCK_FILTER: {
      return {
        ...state,
        selectedStockFilter: payload as ProductPageStockFilters,
      };
    }
    case LeasingActions.SET_SELECTED_BUSINESS_TYPE: {
      return {
        ...state,
        selectedBusinessType: payload as ELeasingBusinessType,
      };
    }
    case LeasingActions.SET_PRODUCT_DATA: {
      return {
        ...state,
        productData: payload as ProductPageQuery_search_Search_advert_Advert,
      };
    }
    case LeasingActions.SET_LEASE_OFFERS_DATA: {
      return {
        ...state,
        leaseOffersData:
          payload as LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms[],
      };
    }
    case LeasingActions.SET_LEASING_MODAL: {
      return {
        ...state,
        leasingModal: payload as LeasingModalState,
      };
    }
    case LeasingActions.SET_CLOSE_LEASING_MODAL: {
      return {
        ...state,
        leasingModal: initState.leasingModal,
      };
    }
    case LeasingActions.SET_LEASE_OPTION: {
      return {
        ...state,
        leaseOption: payload as string,
      };
    }
    case LeasingActions.SET_ALL_LEASE_STATE: {
      return {
        ...state,
        ...(payload as LeasingState),
      };
    }
    case LeasingActions.SET_BUSINESS_TYPE_ERROR: {
      return {
        ...state,
        businessTypeError: payload as boolean,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};
