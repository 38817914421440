import { Link } from "@at/design-system-elements";
import { ThemeSelectors } from "@at/design-system-foundation";
import { BREAKPOINTS } from "@at/window-utils/lib/responsive-utils/match-media.util";
import styled from "styled-components";

const BREAKPOINT_FULL = 1450;

export const NavStyled = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  position: relative;
  padding: 12px 0;

  @media print {
    display: none;
  }
`;

export const Share = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 32px;
  white-space: nowrap;

  @media screen and (min-width: ${BREAKPOINTS.MEDIUM}px) {
    font-size: 16px;
    line-height: 40px;
  }

  @media screen and (min-width: ${BREAKPOINT_FULL}px) {
    line-height: 44px;
  }
`;

export const Save = styled.div`
  display: flex;
  .save-advert-button {
    box-shadow: none;
    padding: 0;
    margin-right: 1px;
    height: auto;
    width: 30px;
    :hover {
      color: #780b0b;
    }
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;
export const BackToSearchLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: auto;
  white-space: nowrap;
  border-bottom: none;

  @media screen and (min-width: ${BREAKPOINTS.MEDIUM}px) {
    font-size: 16px;
  }

  @media screen and (min-width: ${BREAKPOINT_FULL}px) {
    line-height: 44px;
  }

  svg {
    display: block;
    fill: currentColor;
  }
`;

export const AdvertLinkCopy = styled.span`
  margin: 0;

  @media screen and (min-width: ${BREAKPOINTS.MEDIUM}px) {
    margin: 0 ${({ theme }) => ThemeSelectors.getGroupOneSpacing(theme)};
  }
`;
