import { useContext } from "react";

import { AdvertContext } from "../../connected-components/hoc/product-page.hoc";

export const useAdvert = () => {
  return useContext(AdvertContext).advert;
};

export const useRestAdvert = () => {
  return useContext(AdvertContext).restAdvert;
};
