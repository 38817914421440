import { ReactElement } from "react";

import { ModalVariant } from "@at/design-system-patterns";

import { LeaseContractType } from "../../../__generated__/graphql-types";
import { LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms } from "../../../connected-components/hoc/query/__generated__/pdp-lease-offers.query";
import { ProductPageQuery_search_Search_advert_Advert } from "../../../connected-components/hoc/query/__generated__/pdp-page-data.query";
import { LeasingActions } from "../actions/leasing.action.keys";

export enum ProductPageStockFilters {
  IN_STOCK = "in-stock",
  ALL = "all-options",
}

export enum ELeasingBusinessType {
  DEFAULT = "",
  SOLE_TRADER = "soleTrader",
  LIMITED_LIABILITY_PARTNERSHIP = "limitedLiabilityPartnership",
  LIMITED_COMPANY = "limitedCompany",
}

export interface LeasingState {
  leaseOption: string;
  selectedStockFilter: ProductPageStockFilters;
  leaseContractType: LeaseContractType;
  productData?: ProductPageQuery_search_Search_advert_Advert;
  leaseOffersData?: LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms[];
  selectedLeaseOffer?: LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms;
  selectedBusinessType: ELeasingBusinessType;

  leasingModal: LeasingModalState;
  businessTypeError?: boolean;
}

export interface ILeasingProvider {
  children: ReactElement | ReactElement[];
}

export type LeasingContextReducer = (
  state: LeasingState,
  action: LeasingContextAction,
) => LeasingState;

export interface LeasingContextAction {
  type: keyof typeof LeasingActions;
  payload?: unknown;
}

export interface LeasingModalState {
  show: boolean;
  type: LeasingModals;
  title: string;
  /** ATDS Modal Variant */
  variant?: ModalVariant;
  footerButtonText?: string;
}

export enum LeasingModals {
  NONE = "NONE",
  TECHNICAL_SPECIFICATIONS = "TECHNICAL_SPECIFICATIONS",
  ALL_OPTIONS_IN_STOCK = "ALL_OPTIONS_IN_STOCK",
  FACTORY_ORDER = "FACTORY ORDER",
  ABOUT_LEASING = "ABOUT_LEASING",
  CONTACT_US = "CONTACT_US",
  FREE_RETURNS = "FREE_RETURNS",
  FREE_DELIVERY = "FREE_DELIVERY",
  NO_ADMIN_FEES = "NO_ADMIN_FEES",
  ROAD_TAX = "ROAD_TAX",
  HOW_LEASING_WORKS = "HOW_LEASING_WORKS",
  WHAT_YOU_GET_WHEN_YOU_LEASE = "WHAT_YOU_GET_WHEN_YOU_LEASE",
  COMMISSION_DISCLOSURE = "COMMISSION_DISCLOSURE",
}
