import React from "react";

import { Switch } from "react-router-dom";

import { PageRoute as PageRouteWithoutPlatformLoadedScripts } from "@at/sauron-platform-core";
import { PageRouteProps } from "@at/sauron-platform-core/lib/page/types";

import { withScripts } from "@at/sauron-platform-spa/lib/script";

import { Channel } from "./__generated__/fed-graphql-types";
import {
  buildLeasingPageName,
  buildPageName,
} from "./connected-components/hoc/track-pagename-builder";
import ContactDealerErrorPage from "./pages/page-wrappers/contact-dealer-error-page/contact-dealer-error-page";
import ContactDealerSuccessPage from "./pages/page-wrappers/contact-dealer-success-page/contact-dealer-success-page";
import ContactDealerWithoutLayoutPage from "./pages/page-wrappers/contact-dealer-without-layout-page/contact-dealer-without-layout-page";
import LeasingEnquiryFormErrorWithoutLayoutPage from "./pages/page-wrappers/leasing-enquiry-form-error-without-layout-page/leasing-enquiry-form-error-without-layout-page";
import LeasingEnquiryFormSuccessWithoutLayoutPage from "./pages/page-wrappers/leasing-enquiry-form-success-without-layout-page/leasing-enquiry-form-success-without-layout-page";
import LeasingEnquiryFormWithoutLayoutPage from "./pages/page-wrappers/leasing-enquiry-form-without-layout-page/leasing-enquiry-form-without-layout-page";
import LeasingPage from "./pages/page-wrappers/leasing-page/leasing-page";
import ProductOrDigitalRetailingPage from "./pages/page-wrappers/product-or-digital-retailing-page/product-or-digital-retailing-page";
import { metaInfo } from "./utils/metaInfo/meta-info.utils";

const PageRoute = withScripts(PageRouteWithoutPlatformLoadedScripts, []);

enum Route {
  CLASSIFIED_NEW = "/classified/advert(/new)?/:advertId",
  CLASSIFIED = "/spa/classified/advert/:advertId",
  CAR_DETAILS_SSR = "/car-ssr/:advertId",
  CAR_DETAILS = "(/spa)?/car-details/:advertId",
  VAN_DETAILS = "(/spa)?/van-details/:advertId",
  BIKE_DETAILS = "(/spa)?/bike-details/:advertId",
  CARAVAN_DETAILS = "(/spa)?/caravan-details/:advertId",
  MOTORHOME_DETAILS = "(/spa)?/motorhome-details/:advertId",
  TRUCK_DETAILS = "(/spa)?/truck-details/:advertId",
  FARM_DETAILS = "(/spa)?/farm-machinery-details/:advertId",
  PLANT_DETAILS = "(/spa)?/plant-machinery-details/:advertId",
  CYCLE_DETAILS = "(/spa)?/electric-bike-details/:advertId",
}

enum LeasingRoute {
  CARS_LEASING_PDP = "/cars/leasing/product/:advertId",
  VANS_LEASING_PDP = "/vans/leasing/product/:advertId",
}

enum EnquiryFormRoute {
  ENQUIRY_FORM = "/contact-dealer/:dealerId/:advertId",
  ENQUIRY_FORM_ERROR = "/contact-dealer/:dealerId/:advertId/fail",
  ENQUIRY_FORM_SUCCESS = "/contact-dealer/:dealerId/:advertId/success",
}

enum LeasingEnquiryFormRoute {
  LEASING_ENQUIRY_FORM = "/:channel/leasing/request-a-callback",
  LEASING_ENQUIRY_FORM_ERROR = "/:channel/leasing/request-a-callback/error",
  LEASING_ENQUIRY_FORM_SUCCESS = "/:channel/leasing/request-a-callback/success",
}

export interface RouteProps extends PageRouteProps {
  channel: Channel;
}

export type EnquiryRouteProps = PageRouteProps;

export const routes: Record<string, RouteProps> = {
  [Route.BIKE_DETAILS]: {
    channel: Channel.bikes,
  },
  [Route.CAR_DETAILS]: {
    channel: Channel.cars,
    pageData: { ...metaInfo[Channel.cars], canonical: "" },
  },
  [Route.CAR_DETAILS_SSR]: {
    channel: Channel.cars,
  },
  [Route.CARAVAN_DETAILS]: {
    channel: Channel.caravans,
  },
  [Route.CLASSIFIED_NEW]: {
    channel: Channel.cars,
  },
  [Route.CLASSIFIED]: {
    channel: Channel.cars,
  },
  [Route.CYCLE_DETAILS]: {
    channel: Channel.cycles,
  },
  [Route.FARM_DETAILS]: {
    channel: Channel.farm,
  },
  [Route.MOTORHOME_DETAILS]: {
    channel: Channel.motorhomes,
  },
  [Route.PLANT_DETAILS]: {
    channel: Channel.plant,
  },
  [Route.TRUCK_DETAILS]: {
    channel: Channel.trucks,
  },
  [Route.VAN_DETAILS]: {
    channel: Channel.vans,
  },
};

export const leasingRoutes: Record<string, RouteProps> = {
  [LeasingRoute.CARS_LEASING_PDP]: {
    channel: Channel.cars,
    pageData: {
      title: "Car Leasing Deal | Auto Trader UK",
      metaDescription: "",
    },
  },
  [LeasingRoute.VANS_LEASING_PDP]: {
    channel: Channel.vans,
    pageData: {
      title: "Van Leasing Deal | Auto Trader UK",
      metaDescription: "",
    },
  },
};

export const enquiryFormRoutes: Record<string, EnquiryRouteProps> = {
  [EnquiryFormRoute.ENQUIRY_FORM]: {
    component: ContactDealerWithoutLayoutPage,
    trackingEventData: {},
    pageData: {
      title: "Enquiry Form | Auto Trader UK",
      metaDescription: "",
      meta: [{ name: "robots", content: "noindex" }],
    },
    delayPageViewEvent: true,
  },
  [EnquiryFormRoute.ENQUIRY_FORM_SUCCESS]: {
    component: ContactDealerSuccessPage,
    trackingEventData: { pageName: "" },
    delayPageViewEvent: true,
  },
  [EnquiryFormRoute.ENQUIRY_FORM_ERROR]: {
    component: ContactDealerErrorPage,
    trackingEventData: {},
    pageData: {
      title: "Error page | Auto Trader UK",
      metaDescription: "",
      meta: [{ name: "robots", content: "noindex" }],
    },
  },
};

export const leasingEnquiryFormRoutes: Record<string, EnquiryRouteProps> = {
  [LeasingEnquiryFormRoute.LEASING_ENQUIRY_FORM]: {
    component: LeasingEnquiryFormWithoutLayoutPage,
    trackingEventData: { pageName: "leasing:request-callback" },
    pageData: {
      title: "Leasing Request Call back | Auto Trader UK",
      metaDescription: "",
      meta: [{ name: "robots", content: "noindex" }],
    },
  },
  [LeasingEnquiryFormRoute.LEASING_ENQUIRY_FORM_ERROR]: {
    component: LeasingEnquiryFormErrorWithoutLayoutPage,
    trackingEventData: { pageName: "leasing:request-callback-error" },
    pageData: {
      title: "Leasing Request Call back Error | Auto Trader UK",
      metaDescription: "",
      meta: [{ name: "robots", content: "noindex" }],
    },
  },
  [LeasingEnquiryFormRoute.LEASING_ENQUIRY_FORM_SUCCESS]: {
    component: LeasingEnquiryFormSuccessWithoutLayoutPage,
    trackingEventData: { pageName: "leasing:request-callback-success" },
    pageData: {
      title: "Leasing Request Call back Success | Auto Trader UK",
      metaDescription: "",
      meta: [{ name: "robots", content: "noindex" }],
    },
  },
};

export const Routes = () => {
  return (
    <Switch>
      {Object.entries(routes).map(([path, props]) => (
        <PageRoute
          exact
          key={path}
          path={path}
          channel={props.channel}
          component={ProductOrDigitalRetailingPage}
          trackingEventData={{ pageName: buildPageName(props.channel) }}
          delayPageViewEvent={true}
          pageData={props.pageData ?? metaInfo[props.channel]}
          ssrCacheTimeSeconds={0}
        />
      ))}
      {Object.entries(leasingRoutes).map(([path, props]) => (
        <PageRouteWithoutPlatformLoadedScripts
          exact
          key={path}
          path={path}
          channel={props.channel}
          component={LeasingPage}
          trackingEventData={{ pageName: buildLeasingPageName(props.channel) }}
          delayPageViewEvent={true}
          pageData={props.pageData ?? metaInfo[props.channel]}
          ssrCacheTimeSeconds={0}
        />
      ))}
      {Object.entries(enquiryFormRoutes).map(([path, props]) => (
        <PageRoute exact key={path} path={path} {...props} />
      ))}
      {Object.entries(leasingEnquiryFormRoutes).map(([path, props]) => (
        <PageRoute
          exact
          key={path}
          path={path}
          ssrCacheTimeSeconds={0}
          delayPageViewEvent={true}
          {...props}
        />
      ))}
    </Switch>
  );
};
