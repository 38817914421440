import { getChannelSingular } from "@at/formatter-utils/lib/channel-utils/channel.util";
import { Channel } from "@at/sauron-platform-core";
import { parse, ParsedQuery, stringifyUrl } from "query-string";

export const isFromDealerSearchResults = (queryString: string) => {
  return !!queryString && queryString.includes("fromDealerSearchResults");
};

export const isFromWhatCar = (queryString: ParsedQuery<string>) => {
  return queryString?.utm_source?.includes("HM_WC");
};

const isTpf = (channel: Channel) => {
  const tpfChannels = [Channel.trucks, Channel.plant, Channel.farm];
  return tpfChannels.includes(channel);
};

export const getExpiredAdUrl = (search: string, channel: Channel): string => {
  const channelSingular = getChannelSingular(channel);
  const channelSearchUrl = `/${channelSingular}-search`;
  const expiredParams = {
    "expired-ad": true,
  };

  const queryParsed = parse(search);

  if (isTpf(channel)) {
    return stringifyUrl({
      url: channelSearchUrl,
      query: { ...queryParsed, ...expiredParams },
    });
  }

  if (queryParsed["lat-long"] || queryParsed["postcode"]) {
    return stringifyUrl({
      url: channelSearchUrl,
      query: { ...queryParsed, ...expiredParams },
    });
  }

  return stringifyUrl({
    url: channel === Channel.cars ? "/search-form" : `/${channel}/search-form`,
    query: expiredParams,
  });
};

export const getQueryParamValueFromSearch = (
  queryParam: string,
  search: string,
) => {
  return parse(search)[queryParam] || null;
};

export const getParsedQuerySingularValue = <T = string>(
  parsedQuery: ParsedQuery<T>,
  key: string,
): T | null => {
  const value = parsedQuery[key];

  if (!value) {
    return null;
  }

  if (Array.isArray(value)) {
    return value[0] || null;
  }

  return value;
};

export const parseQueryStringValue = (
  search: ParsedQuery<string>,
  name: string,
): number | undefined => {
  return search[name]
    ? Number.parseInt(search[name]?.toString() || "")
    : undefined;
};

export const getFirstValue = (
  search: ParsedQuery<string>,
  name: string,
): string | undefined => {
  const value = search[name];

  if (value && Array.isArray(value) && value.length > 0) {
    return value[0];
  } else if (value && !Array.isArray(value)) {
    return value;
  } else {
    return undefined;
  }
};

export const shareableSearchParams = (
  search: string,
): Record<string, string> => {
  const searchParams = parse(search);

  return JSON.parse(
    JSON.stringify({
      ...searchParams,
      postcode: undefined,
      page: undefined,
      "lat-long": undefined,
    }),
  );
};
