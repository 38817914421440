import { Channel, Platform } from "@at/sauron-platform-core";
import {
  AdvertContext201,
  BuyingJourney100,
  LeasingConfiguration204,
  VdsVehicleInfo101,
} from "@at/track-core";

import { LeaseContractType } from "../__generated__/graphql-types";
// eslint-disable-next-line import/order
import { getBusinessType } from "./leasing/getBusinessType/get-business-type";

import { LeasingState } from "../contexts/leasing/types/leasing.types";

export const getPageAndContextData = (
  pageNameSuffix: string,
  device: Platform,
  channel: Channel,
) => {
  const platform = device === "mobile" ? "mobile-web" : device;
  const page_name = `${channel}:${pageNameSuffix}`;
  const page_name_granular = `${platform}:${page_name}`;
  const pageData = {
    channel,
    platform,
    page_name,
    page_name_granular,
  };
  return pageData;
};

export const getSPTrackingData = (pageNameSuffix: string, channel: string) => {
  const spTrackingData = {
    channel: channel,
    pageName: `${channel}:${pageNameSuffix}`,
  };
  return spTrackingData;
};

export const addToUtagData = (dataToAdd: { [key: string]: string }) => {
  for (const key of Object.keys(dataToAdd)) {
    if (window?.utag_data && dataToAdd[key] !== "") {
      window.utag_data[key] = dataToAdd[key];
    }
  }
};

export const LeasingConfiguration = "LeasingConfiguration204";

export const getLeasingConfigEntity = (
  state: LeasingState,
): LeasingConfiguration204 => {
  const { selectedLeaseOffer, leaseContractType, selectedBusinessType } = state;

  const { monthlyPrice, monthlyPriceExVat } = selectedLeaseOffer || {};

  const monthlyPayment =
    leaseContractType === LeaseContractType.personal
      ? monthlyPrice
      : monthlyPriceExVat;

  return {
    SCHEMA_KEY: LeasingConfiguration,
    deposit: selectedLeaseOffer?.depositMonths ?? 0,
    term: selectedLeaseOffer?.termMonths ?? 0,
    trim: selectedLeaseOffer?.upholstery ?? "",
    colour: selectedLeaseOffer?.colour ?? "",
    annual_mileage: selectedLeaseOffer?.totalMileage ?? 0,
    monthly_payment: monthlyPayment ?? 0,
    ...(selectedLeaseOffer?.deliveryEstimateDays !== null && {
      availability: selectedLeaseOffer?.deliveryEstimateDays,
    }),
    lease_type:
      leaseContractType === LeaseContractType.business
        ? "BUSINESS"
        : "PERSONAL",
    lease_sub_type: getBusinessType(selectedBusinessType),
  };
};

export const getVdsVehicleInfoEntity = (
  state: LeasingState,
): VdsVehicleInfo101 => {
  return {
    SCHEMA_KEY: "VdsVehicleInfo101",
    standard_make: state.productData?.specification.make,
    standard_model: state.productData?.specification.model,
    fuel_type: state.productData?.specification?.fuel || "",
    transmission: state.productData?.specification.techData?.transmission || "",
    derivative_id: state.productData?.specification?.derivativeId || "",
  };
};

export const getLeasingAdvertContextEntity = (
  state: LeasingState,
): AdvertContext201 => {
  return {
    SCHEMA_KEY: "AdvertContext201",
    id: state.productData?.id || "",
    make: state.productData?.specification.make,
    model: state.productData?.specification.model,
  };
};

export const getLeasingBuyingJourneyEntity = (): BuyingJourney100 => {
  return {
    SCHEMA_KEY: "BuyingJourney100",
    product: "leasing",
    view_type: "product-page",
  };
};

export const getLeasingFPAPageViewEventEntities = (state: LeasingState) => [
  getVdsVehicleInfoEntity(state),
  getLeasingAdvertContextEntity(state),
  getLeasingBuyingJourneyEntity(),
  getLeasingConfigEntity(state),
];
