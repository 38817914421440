import { isUserInTestBucket } from "@at/ab-test-utils/lib/abtest.utils";

export enum AbTestNames {
  DEAL_BUILDER_DUAL_ENTRY_CTA_TEST = "deA",
  MVT_MAP_AND_LOCATION_VARIANT_A = "mlA",
  VEHICLE_CHECK_IN_DEAL_BUILDER = "vcdT",
  RED_BUTTON_TEST = "rbT",
  RED_BUTTON_TEST2 = "rbT2",
  LINK_UNDERLINE_TEST = "rbU",
  START_APPLICATION_TEST = "lsaT",
  LEASING_LIMITED_CHECKOUT = "llcT",
  USED_CAR_FINANCE_CALCULATOR_TEST = "ucFT",
  USED_CAR_FINANCE_CALCULATOR_CONTROL = "ucFC",
}

export const isUserInRedButtonTest =
  isUserInTestBucket(AbTestNames.RED_BUTTON_TEST) ||
  isUserInTestBucket(AbTestNames.RED_BUTTON_TEST2);

export const isUserInDualEntryCtaTest = () =>
  isUserInTestBucket(AbTestNames.DEAL_BUILDER_DUAL_ENTRY_CTA_TEST) &&
  !isUserInRedButtonTest;

export const isInVehicleCheckTest = isUserInTestBucket(
  AbTestNames.VEHICLE_CHECK_IN_DEAL_BUILDER,
);

export const isUserInLinkUnderlineTest = isUserInTestBucket(
  AbTestNames.LINK_UNDERLINE_TEST,
);

export const isUserInUsedCarFinanceCalculatorTest = () =>
  isUserInTestBucket(AbTestNames.USED_CAR_FINANCE_CALCULATOR_TEST);

export const isUserInUsedCarFinanceCalculatorControl = () =>
  isUserInTestBucket(AbTestNames.USED_CAR_FINANCE_CALCULATOR_CONTROL);
