import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { ReactQueryDevtools } from "react-query/devtools";

// eslint-disable-next-line react/display-name
export const withQueryClient = (WC: FC) => () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <WC />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};
