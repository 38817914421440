import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";

import { IconArrowLeft } from "@at/design-system-icons";
import { dataAttrGUI } from "@at/formatter-utils/lib/data-attr-utils/data-attr-utils";

import loadable from "@loadable/component";

import * as Styled from "./advert-navigation.styles";
import { getBackToSearchResultsLink } from "./advert-navigation.utils";
import {
  AdvertBreakOutSection,
  AdvertContainerStyles,
} from "../../pages/product-page.styles";
import { useAdvert } from "../../utils/hooks/use-advert";
import { useChannel } from "../../utils/hooks/use-channel";
import { useDealer } from "../../utils/hooks/use-dealer";
import { useTracking } from "../../utils/tracking/use-tracking";
import AdvertShare from "../advert/advert-image/actions/action-share";

export enum AdvertNavigationPosition {
  BOTTOM = "BOTTOM",
  TOP = "TOP",
}

interface AdvertNavigationProps {
  position?: AdvertNavigationPosition;
}

const LazyAdvertSaveIcon = loadable(() => {
  return import("../advert/advert-image/actions/action-save-button");
});

export const AdvertNavigation = ({ position }: AdvertNavigationProps) => {
  const advert = useAdvert();
  const { dealerId } = useDealer() || {};

  const channel = useChannel();
  const { search } = useLocation();
  const { trackEvent } = useTracking();
  const backToSearchResultsUrl = getBackToSearchResultsLink(
    search,
    channel,
    advert,
    dealerId,
  );

  return (
    <AdvertBreakOutSection position={position} nav>
      <AdvertContainerStyles nav>
        <Styled.NavStyled>
          {backToSearchResultsUrl && (
            <Styled.BackToSearchLink
              routerLink={false}
              {...{ [dataAttrGUI]: "back-to-search-link" }}
              href={backToSearchResultsUrl}
              onClick={() => trackEvent({ trackingLabel: "back-to-results" })}
            >
              <IconArrowLeft
                title={""}
                viewBox="0 0 24 24"
                customSize={{ width: 24, height: 24 }}
              />
              <Styled.AdvertLinkCopy>Back to results</Styled.AdvertLinkCopy>
            </Styled.BackToSearchLink>
          )}
          <Suspense fallback={""}>
            <Styled.Save>
              <LazyAdvertSaveIcon />
            </Styled.Save>
          </Suspense>
          <Styled.Share>
            <AdvertShare />
          </Styled.Share>
        </Styled.NavStyled>
      </AdvertContainerStyles>
    </AdvertBreakOutSection>
  );
};
