import { atdsTheme } from "@at/design-system-foundation";
import { DefaultTheme } from "@at/design-system-tokens/dist/styles";
import { css } from "styled-components";

const redDefault = "#EB0037";
const redHover = "#BC002C";
const midnight = atdsTheme.v1.color.blue.dark;

export const redButtonTheme: DefaultTheme = {
  ...atdsTheme,
  v1: {
    ...atdsTheme.v1,
    badges: {
      ...atdsTheme.v1.badges,
      default: {
        background: midnight,
        color: "#ffffff",
      },
    },
    buttons: {
      ...atdsTheme.v1.buttons,
      primary: {
        ...atdsTheme.v1.buttons.primary,
        default: {
          ...atdsTheme.v1.buttons.primary.default,
          background: {
            ...atdsTheme.v1.buttons.primary.default.background,
            default: redDefault,
            hover: redHover,
            active: redHover,
          },
        },
      },
      secondary: {
        ...atdsTheme.v1.buttons.secondary,
        default: {
          ...atdsTheme.v1.buttons.secondary.default,
          foreground: {
            ...atdsTheme.v1.buttons.secondary.default.foreground,
            default: midnight,
            hover: midnight,
            active: midnight,
          },
        },
      },
      tertiary: {
        ...atdsTheme.v1.buttons.tertiary,
        default: {
          ...atdsTheme.v1.buttons.tertiary.default,
          background: {
            ...atdsTheme.v1.buttons.tertiary.default.background,
            hover: "#F7F6F5",
            active: "#F7F6F5",
          },
          foreground: {
            ...atdsTheme.v1.buttons.tertiary.default.foreground,
            default: midnight,
            hover: redDefault,
            active: redDefault,
          },
        },
      },
    },
    link: {
      ...atdsTheme.v1.link,
      default: midnight,
      hover: redDefault,
      active: redDefault,
      visited: midnight,
      disabled: "#D1D0CF",
    },
  },
};

export const LinkUnderlineStyles = css`
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
`;
