import React from "react";

import { withLayout } from "@at/sauron-platform-core";

import { withUsedProductPageData } from "../../../connected-components/hoc/product-page.hoc";
import { withQueryClient } from "../../../connected-components/hoc/query-client.hoc";
import { layoutOptions } from "../../../utils/page-wrapper/page-wrapper-utils";
import { ContactDealerSuccess } from "../loadables";

const ContactDealerSuccessPage = () => {
  const WrappedWithData = withUsedProductPageData(
    ContactDealerSuccess,
    ":email-sent",
    "enquiry-sent",
  );
  const WrappedPage = withQueryClient(
    withLayout(WrappedWithData, layoutOptions),
  );

  return <WrappedPage />;
};

export default ContactDealerSuccessPage;
