import "core-js/stable";

import React from "react";
import ReactDOM from "react-dom";

import { atdsTheme } from "@at/design-system-foundation";

import { bootstrap, Script, SinglePageApp } from "@at/sauron-platform-spa";
import { responsiveViewportMatcher } from "@at/window-utils/lib/responsive-utils/match-media.util";

import { ThemeProvider } from "styled-components";

import App from "./app";
import { createCache } from "./cache";

import { isUserInRedButtonTest } from "./utils/ab-test/ab-test.util";
import { redButtonTheme } from "./utils/red-button-test/red-button-test.utils";

const AdvertsApp = () => {
  const cache = createCache();
  return (
    <SinglePageApp
      appName="sauron-adverts-app"
      graphQL={{
        enabled: true,
        cache,
      }}
      config={{
        desktopView: responsiveViewportMatcher("medium"),
      }}
      scripts={{
        /* Here we are opting out of letting the SinglePageApp Platform Component from loading the Retailer Live Person script */
        disabledScripts: [
          {
            script: Script.LivePersonRetailerChatScript,
            reason:
              "Disabling script globally and re-adding where needed further down to avoid conflicts.",
          },
        ],
      }}
    >
      <ThemeProvider theme={isUserInRedButtonTest ? redButtonTheme : atdsTheme}>
        <App />
      </ThemeProvider>
    </SinglePageApp>
  );
};

bootstrap(AdvertsApp, { render: ReactDOM.render });
