import { UtagDimensions } from "@at/consumer-web-snowplow";
import {
  AdvertContext201,
  DigitalRetailingDomain101,
  Entities,
  Feature100,
  PriceIndicator110,
  ReservationFpaDisplay101,
  Retailer101,
  VdsVehicleInfo101,
  VehicleCheck100,
  GalleryImage102,
  Search200,
  SearchJourney100,
} from "@at/track-core";

import { parse } from "query-string";

import { AdvertAttribute } from "./advert-attribute";
import {
  defaultSearchJourneyEntity,
  isValidSearchContext,
} from "./search-journey-utils";
import { Channel } from "../../__generated__/fed-graphql-types";
import {
  AdvertReservationEligibility,
  VehicleCheckStatus,
} from "../../__generated__/graphql-types";
import { AdvertAndDealerByIdWithClickAndCollectLocationsQuery_search_Search_advert_Advert } from "../../connected/query/__generated__/advert-and-dealer-by-id-with-click-and-collection-locations.query";
import { FPADataQuery_search_Search_advert_Advert } from "../../connected-components/hoc/query/__generated__/fpa-page-data.query";
import {
  meetsACOnlineFinanceApplicationRequirements,
  meetsATOnlineFinanceApplicationRequirements,
} from "../online-finance-application/online-finance-application.utils";
import { retailerHasSellerPromise } from "../seller-promise/seller-promise.utils";
import {
  getParsedQuerySingularValue,
  parseQueryStringValue,
} from "../uri/uri-utils";

export enum OdsTrackingSource {
  DEAL_BUILDER = "DEAL_BUILDER",
  DEALER_ENQUIRY = "DEALER_ENQUIRY",
  VEHICLE_CLICK = "VEHICLE_CLICK",
}

export enum ImageGalleryState {
  in_page = "in_page",
  in_grid = "in_grid",
  in_slider = "in_slider",
}

const getMakeModel = (
  advert:
    | FPADataQuery_search_Search_advert_Advert
    | AdvertAndDealerByIdWithClickAndCollectLocationsQuery_search_Search_advert_Advert,
) => {
  return {
    make: advert.specification?.make?.toLowerCase(),
    model: advert.specification?.model?.toLowerCase().slice(0, 40),
  };
};

const buildFeature = (type: string, productCode?: string): Feature100 => {
  return {
    SCHEMA_KEY: "Feature100",
    type,
    product_code: productCode,
  };
};

const getAdvertContext = (
  advert: FPADataQuery_search_Search_advert_Advert,
): AdvertContext201 => {
  const { make, model } = getMakeModel(advert);
  return {
    SCHEMA_KEY: "AdvertContext201",
    id: advert.id || "",
    advertiser_id: advert.retailerId === "-1" ? undefined : advert.retailerId,
    advertiser_type: advert.retailerId === "-1" ? "private" : "dealer",
    make,
    model,
    condition: advert.condition?.toLowerCase() as AdvertContext201["condition"],
    ...(!!advert.year && { year: advert.year }),
    ...(!!advert.price && { price: advert.price }),
  };
};

const getVdsVehicleContext = (
  advert: FPADataQuery_search_Search_advert_Advert,
): VdsVehicleInfo101 => ({
  SCHEMA_KEY: "VdsVehicleInfo101",
  generation_id: advert.generation?.generationId,
  derivative_id: advert.specification?.derivativeId,
  standard_make: advert.specification?.make,
  standard_model: advert.specification?.model,
  standard_body_type: advert.specification?.bodyType,
  fuel_type: advert.specification?.fuel,
  standard_is_crossover: advert.specification?.isCrossover || false,
  colour: advert.colour,
  transmission: advert.specification?.transmission,
  standard_drivetrain: advert.specification?.oemDrivetrain,
  standard_engine_size_cc: advert.specification?.engine?.sizeCC || 0,
  standard_engine_size_litre:
    advert.specification?.engine?.sizeLitres?.toString(),
  standard_engine_size_unit: advert.specification?.engine?.power?.unit,
  axle_config: advert.specification?.axleConfig,
  cab_type: advert.specification?.cabType,
  emission_class: advert.specification?.emissionClass,
  standard_style: advert.specification?.style,
  standard_sub_style: advert.specification?.subStyle,
  standard_trim: advert.specification?.trim,
  standard_layout_bed: advert.specification?.bedroomLayout,
  standard_layout_end: advert.specification?.endLayout,
  wheelbase_type: advert.specification?.wheelbase,
});

const getPriceIndicatorContext = (
  advert: FPADataQuery_search_Search_advert_Advert,
): PriceIndicator110 | undefined => {
  if (advert?.priceIndicatorRating) {
    return {
      SCHEMA_KEY: "PriceIndicator110",
      rating: (advert?.priceIndicatorRating?.toLowerCase() ||
        "noanalysis") as PriceIndicator110["rating"],
      rating_label: advert?.priceIndicatorRatingLabel || "",
      deviation: advert?.priceDeviation || undefined,
    };
  }
};

const getVehicleCheckContext = (
  advert: FPADataQuery_search_Search_advert_Advert,
): VehicleCheck100 | undefined => {
  if (advert?.vehicleCheckId) {
    return {
      SCHEMA_KEY: "VehicleCheck100",
      id: advert?.vehicleCheckId || "",
      all_clear: advert?.vehicleCheckStatus === VehicleCheckStatus.PASSED,
    };
  }
};

const getReservationContext = (
  advert: FPADataQuery_search_Search_advert_Advert,
): ReservationFpaDisplay101 | undefined => {
  if (
    advert.reservation?.eligibility &&
    advert.reservation?.feeCurrency &&
    advert.reservation?.feeInFractionalUnits !== undefined &&
    advert.reservation?.feeInFractionalUnits !== null
  ) {
    return {
      SCHEMA_KEY: "ReservationFpaDisplay101",
      advert_reservation_status: advert.reservation.eligibility,
      reservation_fee_currency: advert.reservation
        ?.feeCurrency as ReservationFpaDisplay101["reservation_fee_currency"],
      reservation_fee_in_fractional_units:
        advert.reservation?.feeInFractionalUnits || 0,
    };
  }
};

const getRetailerContext = (
  advert: FPADataQuery_search_Search_advert_Advert,
): Retailer101 | undefined => {
  if (advert.retailerId) {
    return {
      SCHEMA_KEY: "Retailer101",
      id: advert.retailerId,
    };
  }
};

const getDigitalRetailingContext = (
  pageName?: string,
): DigitalRetailingDomain101 => ({
  SCHEMA_KEY: "DigitalRetailingDomain101",
  page_name: pageName == null ? "fpa" : pageName,
  journey: "deal-builder",
  digital_retailing_function: "deal",
});

const getSearchContext = (searchId: string, pageNumber: number): Search200 => {
  return {
    SCHEMA_KEY: "Search200",
    search_id: searchId,
    page_number: pageNumber,
    total_results: 0,
  };
};

const getSearchJourneyContext = (journey: string | null): SearchJourney100 => {
  if (journey && isValidSearchContext(journey)) {
    return {
      SCHEMA_KEY: "SearchJourney100",
      search_context: journey,
    };
  }
  return defaultSearchJourneyEntity;
};

export const getFPAPageViewEventEntities = (
  advert?: FPADataQuery_search_Search_advert_Advert,
  channel?: Channel,
  pageName?: string,
  searchParams?: string,
): Entities => {
  if (!advert || !channel) {
    return [];
  }

  const parsedSearch = parse(searchParams || "");
  const searchId = getParsedQuerySingularValue(parsedSearch, "searchId");
  const pageNumber = parseQueryStringValue(parsedSearch, "page") || 1;
  const journey = getParsedQuerySingularValue(parsedSearch, "journey");

  const entities: Entities = [
    getAdvertContext(advert),
    getVdsVehicleContext(advert),
  ];

  const priceIndicatorContext = getPriceIndicatorContext(advert);
  if (priceIndicatorContext) {
    entities.push(priceIndicatorContext, buildFeature("price_indicator"));
  }

  const vehicleCheckContext = getVehicleCheckContext(advert);
  if (vehicleCheckContext) {
    entities.push(vehicleCheckContext, buildFeature("vehicle_check", "VCHECK"));
  }

  const reservationContext = getReservationContext(advert);
  if (reservationContext) {
    entities.push(reservationContext);
  }

  const retailerContext = getRetailerContext(advert);
  if (retailerContext) {
    entities.push(retailerContext);
  }

  if (
    advert.reservation?.eligibility === AdvertReservationEligibility.ELIGIBLE
  ) {
    entities.push(buildFeature("reservation_eligible", "OLRESERVATIONS"));
  }
  const products = advert?.dealer?.servicesOffered?.products;

  if (
    meetsATOnlineFinanceApplicationRequirements(
      products,
      advert.condition,
      channel,
    )
  ) {
    entities.push(buildFeature("online_finance_application", "ATFINANCEOLA"));
  }

  if (
    meetsACOnlineFinanceApplicationRequirements(
      products,
      advert.condition,
      channel,
    )
  ) {
    entities.push(buildFeature("online_finance_application", "ACFINANCEOLA"));
  }

  if (retailerHasSellerPromise(channel, advert.condition)) {
    entities.push(buildFeature("seller_promise", "SELLERPROMISE"));
  }

  const dealBuilderAdvert =
    advert?.capabilities?.digitalRetailing?.enabled &&
    advert.reservation?.eligibility !==
      AdvertReservationEligibility.NOT_ELIGIBLE;

  if (dealBuilderAdvert) {
    entities.push(
      buildFeature("digital_retailing", "DIGITALRETAIL"),
      getDigitalRetailingContext(pageName),
    );
  }

  if (searchId) {
    entities.push(getSearchContext(searchId, pageNumber));
  }
  entities.push(getSearchJourneyContext(journey));

  return entities;
};

export const getGalleryImageContext = (
  imageId: string,
  galleryState: ImageGalleryState,
  index: number,
  galleryCategory?: string,
): GalleryImage102 => ({
  SCHEMA_KEY: "GalleryImage102",
  image_id: imageId,
  gallery_state: galleryState,
  gallery_position: index,
  image_viewed_timestamp: new Date().toISOString(),
  gallery_category: galleryCategory,
});

type UtagDimensionsKeys = keyof UtagDimensions;

export const updateUtagData = (utagDataToAdd: UtagDimensions) => {
  if (!window.utag_data) {
    window.utag_data = {};
  }

  const keys = Object.keys(utagDataToAdd) as UtagDimensionsKeys[];
  for (const key of keys) {
    if (utagDataToAdd && utagDataToAdd[key] !== "") {
      window.utag_data[key] = utagDataToAdd[key];
    }
  }
};

const getAdvertAttributes = (
  advert:
    | FPADataQuery_search_Search_advert_Advert
    | AdvertAndDealerByIdWithClickAndCollectLocationsQuery_search_Search_advert_Advert,
): string => {
  const advertAttributesArray = [];
  if (advert?.stockType) {
    advertAttributesArray.push(advert.stockType);
  }
  if (
    advert?.dealer?.servicesOffered?.hasHomeDelivery &&
    advert?.homeDeliveryRegionCodes?.length &&
    advert.homeDeliveryRegionCodes.length > 0
  ) {
    advertAttributesArray.push(AdvertAttribute.HOME_DELIVERY);
  }

  if (advert?.dealer?.servicesOffered?.hasVideoWalkAround) {
    advertAttributesArray.push(AdvertAttribute.LIVE_VIDEO_VIEWING);
  }
  return advertAttributesArray.join(",") || "";
};

export const mapToUtagData = (
  pageName: string,
  advert?:
    | FPADataQuery_search_Search_advert_Advert
    | AdvertAndDealerByIdWithClickAndCollectLocationsQuery_search_Search_advert_Advert,
  channel?: string,
): UtagDimensions => {
  if (!advert) {
    return {};
  }

  const body_type = advert.specification?.bodyType || "";
  const vehicle_price = advert?.price?.toString() || "";
  const fuel_type = advert.specification?.fuel || "";
  const gearbox = advert.specification?.transmission || "";
  const vehicle_year = advert?.year?.toString() || "";
  const condition = advert?.condition?.toString() || "";
  const advert_attributes = getAdvertAttributes(advert);
  const { make = "", model = "" } = getMakeModel(advert);
  const product_page_type = advert?.capabilities?.digitalRetailing?.enabled
    ? "digital_retailing"
    : "standard";
  return {
    page_name: pageName,
    channel,
    advert_attributes,
    ad_id: advert.id,
    make,
    model,
    fuel_type,
    body_type,
    gearbox,
    vehicle_price,
    vehicle_year,
    condition,
    product_page_type,
  };
};

export const comScorePageViewTrack = () => {
  window.self.COMSCORE &&
    window.self.COMSCORE.beacon({ c1: "2", c2: "6035755", cs_ucfr: "1" });
};
