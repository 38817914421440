/* eslint-disable react/display-name */

import React, { createContext, FC } from "react";
import { useLocation, useParams } from "react-router-dom";

import { ErrorDetail } from "@at/connected-utils/lib/error/error-detail";
import { postcodeIsValid } from "@at/formatter-utils/lib/postcode-utils/postcode-validity.util";
import { usePostcode } from "@at/hooks/lib/postcode";
import { Advert } from "@at/product-page-aggregator";
import { useQuery } from "@at/sauron-platform-graphql";

import {
  FPADataQuery,
  FPADataQueryVariables,
  FPADataQuery_search_Search_advert_Advert,
} from "./query/__generated__/fpa-page-data.query";
import { fPADataGql } from "./query/fpa-page-data.query";
import { buildPageName } from "./track-pagename-builder";
import { PreloadGalleryPageImageLinks } from "../../components/preload-gallery-page-images-links/preload-gallery-page-images-links";
import { SkeletonProductPage } from "../../pages/page-wrappers/loadables";
import {
  advertisingLocations,
  getAdvertisingLocationFromChannel,
} from "../../utils/channel/channel-utils";
import { useChannel } from "../../utils/hooks/use-channel";
import { useFetchAdvert } from "../../utils/hooks/use-fetch-advert";
import {
  getFPAPageViewEventEntities,
  mapToUtagData,
  updateUtagData,
} from "../../utils/tracking/tracking-data-utils";
import { useTracking } from "../../utils/tracking/use-tracking";
import { getExpiredAdUrl } from "../../utils/uri/uri-utils";

export interface AdvertContextProps {
  advert: FPADataQuery_search_Search_advert_Advert;
  restAdvert?: Advert;
}

interface AdvertPageParams {
  advertId: string;
}

export const AdvertContext = createContext<AdvertContextProps>({
  advert: {} as unknown as FPADataQuery_search_Search_advert_Advert,
  restAdvert: {} as unknown as Advert,
});

export const withUsedProductPageData =
  (WC: FC, trackingContextName?: string, domainEntityPageName?: string) =>
  () => {
    const { advertId } = useParams<AdvertPageParams>();
    const { postcode } = usePostcode();
    const channel = useChannel();
    const { search } = useLocation();
    const validPostcode = postcodeIsValid(postcode) ? postcode : "";

    const { data, error, loading } = useQuery<
      FPADataQuery,
      FPADataQueryVariables
    >(fPADataGql, {
      errorPolicy: "all",
      variables: {
        advertId,
        numberOfImages: 100,
        searchOptions: {
          advertisingLocations: [getAdvertisingLocationFromChannel(channel)],
          postcode: validPostcode,
          collectionLocationOptions: {
            searchPostcode: validPostcode,
          },
          channel,
        },
        postcode: validPostcode,
      },
    });

    const {
      data: restAdvert,
      isLoading: restLoading,
      error: restError,
    } = useFetchAdvert();

    const pageName = buildPageName(channel, trackingContextName);
    const entities = getFPAPageViewEventEntities(
      data?.search.advert,
      channel,
      domainEntityPageName == null ? "fpa" : domainEntityPageName,
      search,
    );
    const utagData = mapToUtagData(pageName, data?.search.advert, channel);
    const { trackPageViewEvent, trackEvent } = useTracking({
      pageName,
      utagData,
      domainEntityPageName,
      pageViewAdvert: data?.search.advert,
    });

    if (loading || restLoading) {
      return <SkeletonProductPage />;
    }

    const advert = data?.search.advert;
    const hasNotFoundError =
      !!error?.graphQLErrors.some(
        (error) => error.extensions?.statusCode === 404,
      ) || restError?.response.status === 404;

    const isAdvertExpired =
      advert &&
      !advert.advertisedLocations.some((item) =>
        advertisingLocations[channel].includes(item),
      );

    if (hasNotFoundError || isAdvertExpired) {
      const expiredAdUrl = getExpiredAdUrl(location.search, channel);

      if (window && window.location) {
        window.location.href = expiredAdUrl;
      }

      return <SkeletonProductPage />;
    }

    if (restError) {
      // eslint-disable-next-line no-console
      console.error("Error when calling PPA", restError);
    }

    if (advert && restAdvert) {
      updateUtagData(utagData);

      trackPageViewEvent({ extraEntities: entities, utagDimensions: utagData });
      const isUsingNewTab = window.history.length === 1;
      if (isUsingNewTab) {
        trackEvent({ trackingLabel: "opened-in-new-tab" });
      }
      return (
        <AdvertContext.Provider value={{ advert, restAdvert }}>
          <PreloadGalleryPageImageLinks images={advert.imageList?.images} />
          <WC />
        </AdvertContext.Provider>
      );
    }

    return <ErrorDetail error={error} />;
  };
