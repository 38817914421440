import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { Configuration, ControllerApi } from "@at/product-page-aggregator";

import { useChannel } from "./use-channel";

interface AdvertPageParams {
  advertId: string;
}

const PPA_RESPONSE_KEY = "product-page-ppa-response";

export const useFetchAdvert = () => {
  const { advertId } = useParams<AdvertPageParams>();
  const channel = useChannel();

  const { data, isLoading, error } = useQuery(
    PPA_RESPONSE_KEY,
    () => {
      const config = new Configuration({ basePath: "" });
      const api = new ControllerApi(config);

      return api.getAdvert({ advertId, channel });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  return {
    data,
    isLoading,
    error,
  };
};
