import { AdvertisingLocation } from "@at/sauron-search-form-builder/lib/__generated__/graphql-types";

import {
  AdvertQuery,
  LeaseContractType,
} from "../../../__generated__/graphql-types";
import { ELeasingBusinessType } from "../../../contexts/leasing/types/leasing.types";

export const DEFAULT_LEASING_OPTION = "cheapest";

export const DEFAULT_ADVERTISING_LOCATIONS = [
  AdvertisingLocation.at_lease_virtual_car,
  AdvertisingLocation.at_lease_new_car,
];

export enum LeaseOfferType {
  PROMOTED = "PROMOTED",
  SPECIAL = "SPECIAL",
}
export const MILEAGE_OPTIONS = [
  "6000",
  "8000",
  "10000",
  "12000",
  "15000",
  "20000",
  "25000",
  "30000",
];
export const CONTRACT_LENGTH = ["24", "36", "48", "60"];
export const INITIAL_PAYMENT_MULTIPLIER = ["1", "3", "6", "9", "12"];

export const advertisingLocation: AdvertisingLocation[] = [
  AdvertisingLocation.at_lease_new_car,
  AdvertisingLocation.at_lease_virtual_car,
];

export interface PageState extends AdvertQuery {
  leaseContractType: LeaseContractType;
  leaseOfferTypes: LeaseOfferType[] | null;
  leaseOption: string;
  selectedBusinessType: ELeasingBusinessType;
  pageNumber: number;
  showTermFilters: boolean;
  showSearchFilters: boolean;
  sort: string;
  page: string;
  history?: History;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasKey(obj: object, key: keyof any): key is keyof object {
  return key in obj;
}

export const getLeaseOfferTypeValue = (
  value: string | null | undefined,
): LeaseOfferType | null => {
  if (value != null && hasKey(LeaseOfferType, value)) {
    return LeaseOfferType[value];
  }
  return null;
};
