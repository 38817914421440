import React from "react";

import { useAppContext } from "@at/sauron-platform-core/lib/app/app-context.hoc";

import * as Styles from "../product-page.styles";

const SkeletonLeadFormPage = () => {
  const {
    isDesktop,
    config: { desktopView },
  } = useAppContext();

  return (
    <>
      <Styles.GlobalProductPageStyles />
      <div
        id={
          `layout-` +
          ((desktopView as boolean) || isDesktop() ? "desktop" : "mobile")
        }
        role="alert"
        data-testid="skeleton-layout"
      >
        Loading form...
      </div>
    </>
  );
};

export default SkeletonLeadFormPage;
