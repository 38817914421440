import { LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms } from "../../../connected-components/hoc/query/__generated__/pdp-lease-offers.query";
import { ProductPageStockFilters } from "../../../contexts/leasing/types/leasing.types";

import { getIsOfferInStock } from "../getIsOfferInStock/get-is-offer-in-stock";

export const getFilteredLeaseOffersByStock = (
  leaseOffers: LeaseOffersQuery_search_Search_advert_Advert_leasingOffers_LeaseTerms[],
  stockFilter: ProductPageStockFilters,
) => {
  const filteredLeaseOffers =
    stockFilter === ProductPageStockFilters.IN_STOCK
      ? leaseOffers.filter((offer) => getIsOfferInStock(offer.deliveryEstimate))
      : leaseOffers;

  return filteredLeaseOffers;
};
