import { gql } from "@at/sauron-platform-graphql";

const advertAndDealerByIdWithClickAndCollectionLocationsGql = gql`
  query AdvertAndDealerByIdWithClickAndCollectLocationsQuery(
    $advertId: String!
    $dealerId: String!
    $searchOptions: SearchOptions
  ) {
    search {
      advert(advertId: $advertId, searchOptions: $searchOptions) {
        id
        title
        year
        price
        sellerLocation
        sellerLocationCounty
        description
        colour
        registration
        valueRating
        specification {
          bodyType
          fuel
          transmission
          make
          model
          derivative
        }
        sellerType
        stockType
        condition
        homeDeliveryRegionCodes
        imageList {
          images {
            url
          }
        }
        dealer {
          servicesOffered {
            hasHomeDelivery
            hasVideoWalkAround
          }
        }
        capabilities {
          digitalRetailing {
            enabled
          }
          marketExtensionOem {
            enabled
          }
        }
        reservation {
          eligibility
        }
        sellerProducts
        collectionLocations {
          locations {
            id
            dealerId
            name
            distance
            geoCoordinate {
              latitude
              longitude
            }
          }
        }
      }
      dealer(dealerId: $dealerId) {
        dealerId
        name
        media {
          phoneNumber1
          phoneNumber2
        }
        servicesOffered {
          hasVideoWalkAround
          hasSafeSelling
          hasHomeDelivery
          products
        }
        capabilities {
          marketExtensionCentrallyHeld {
            enabled
          }
          marketExtensionClickAndCollect {
            enabled
          }
        }
      }
    }
  }
`;
export { advertAndDealerByIdWithClickAndCollectionLocationsGql };
