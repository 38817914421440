import React, {
  useMemo,
  useReducer,
  Dispatch,
  createContext,
  useContext,
} from "react";

import { useQueryParameters } from "@at/hooks";

import {
  handleLeaseOfferData,
  useLeasingQueries,
} from "../../../components/leasing/contract-customiser/hooks/useLeasingQueries/use-leasing-queries.hook";
import { useChannel } from "../../../utils/hooks/use-channel";
import { buildStateFromRequest } from "../../../utils/leasing/state/state-utils";
import { leasingReducer } from "../reducer/leasing.reducer";
import { initState } from "../state/init-state";
import {
  ILeasingProvider,
  LeasingContextAction,
  LeasingContextReducer,
  LeasingState,
} from "../types/leasing.types";

const initialDispatch = () => null;
export const LeasingStateContext = createContext<LeasingState>(
  initState as LeasingState,
);
export const LeasingDispatchContext =
  createContext<Dispatch<LeasingContextAction>>(initialDispatch);

LeasingStateContext.displayName = "LeasingStateContext";
LeasingDispatchContext.displayName = "LeasingDispatchContext";

export const LeasingProvider = ({
  children,
}: ILeasingProvider): JSX.Element => {
  const { search } = useQueryParameters();
  const channel = useChannel();

  const { ...stateFromRequest } = buildStateFromRequest(
    search,
    "PRODUCT",
    channel,
  );

  const initialStateForQueries = {
    ...initState,
    ...stateFromRequest,
  };

  const { productPageData, leaseOffersData } = useLeasingQueries(
    initialStateForQueries,
  );

  const processedLeaseOfferData = leaseOffersData?.search.advert.leasingOffers
    ?.length
    ? handleLeaseOfferData(
        leaseOffersData?.search.advert.leasingOffers || [],
        initialStateForQueries,
      )
    : undefined;

  const initialStateFromQueries: Partial<LeasingState> = processedLeaseOfferData
    ? {
        productData: productPageData?.search.advert,
        leaseOffersData: processedLeaseOfferData.filteredLeaseOffers,
        selectedLeaseOffer: processedLeaseOfferData.updatedSelectedLeaseOffer,
        leaseOption: processedLeaseOfferData.updatedLeaseOption,
        selectedStockFilter: processedLeaseOfferData.stockFilter,
      }
    : {};

  const initialState: LeasingState = {
    ...initialStateForQueries,
    ...initialStateFromQueries,
  };

  const [state, dispatch] = useReducer<LeasingContextReducer>(
    leasingReducer,
    initialState,
  );

  const memoizedStateContextValue: LeasingState = useMemo<LeasingState>(
    () => state,
    [state],
  );

  const memoizedDispatchContextValue: Dispatch<LeasingContextAction> = useMemo<
    Dispatch<LeasingContextAction>
  >(() => dispatch, [dispatch]);

  return (
    <LeasingStateContext.Provider value={memoizedStateContextValue}>
      <LeasingDispatchContext.Provider value={memoizedDispatchContextValue}>
        {children}
      </LeasingDispatchContext.Provider>
    </LeasingStateContext.Provider>
  );
};

/** Get the Leasing specific context state object */
export function useLeasingStateContext(): LeasingState {
  const state = useContext(LeasingStateContext);
  return state;
}

/** Get the Leasing specific context dispatch function */
export function useLeasingDispatchContext(): Dispatch<LeasingContextAction> {
  const dispatch = useContext(LeasingDispatchContext);
  return dispatch;
}
