import { useAdvertReservation } from "../advert-reservation";
import { useAdvert } from "../use-advert";

export const useCreateOrder = () => {
  const advert = useAdvert();
  const reservation = useAdvertReservation();

  //? We have to check for reservations because it is mandated
  const hasDigitalRetailingCapability =
    advert.capabilities?.digitalRetailing?.enabled;

  const hasBeenOrdered =
    hasDigitalRetailingCapability && reservation.vehicleIsReserved;
  const showCreateOrderButton =
    hasDigitalRetailingCapability && !reservation.vehicleIsReserved;

  return {
    showCreateOrderButton,
    hasBeenOrdered,
    hasDigitalRetailingCapability,
  };
};
