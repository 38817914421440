import React, { FC } from "react";

import { ConnectedFooter } from "@at/connected-footer";
import { FooterProps } from "@at/design-system-patterns";
// eslint-disable-next-line import/no-named-as-default
import ToastNotification from "@at/sauron-notifications/lib/components/toast-notification";
import styled from "styled-components";

const HidePrint = styled.div`
  @media print {
    display: none;
  }
`;

export const ATFooter: FC<FooterProps> = (props) => {
  return (
    <HidePrint>
      <ConnectedFooter {...props} showFeedbackButton={true} />
      <ToastNotification />
    </HidePrint>
  );
};
