import { TOpeningTimes } from "../../components/opening-hours/opening-hours.types";

export const LEASING_PHONE_NUMBER = "0330 303 9055";

// In stock = 56 days or less
export const IN_STOCK_DELIVERY_ESTIMATES = [
  "14-21 Days",
  "3-5 Weeks",
  "6-8 Weeks",
];

// Standard opening times
export const LEASING_OPENING_HOURS: TOpeningTimes[] = [
  {
    day: "Monday",
    time: "09:00 - 20:00",
  },
  {
    day: "Tuesday",
    time: "09:00 - 20:00",
  },
  {
    day: "Wednesday",
    time: "09:00 - 20:00",
  },
  {
    day: "Thursday",
    time: "09:00 - 20:00",
  },
  {
    day: "Friday",
    time: "09:00 - 20:00",
  },
  {
    day: "Saturday",
    time: "10:00 - 18:00",
  },
  {
    day: "Sunday",
    time: "10:00 - 18:00",
  },
  {
    day: "Bank Holidays",
    time: "Closed",
  },
];

// Xmas opening times '24
export const LEASING_OPENING_HOURS_XMAS: TOpeningTimes[] = [
  {
    day: "Sunday 22nd Dec",
    time: "10:00 - 18:00",
  },
  {
    day: "Monday 23rd Dec",
    time: "09:00 - 20:00",
  },
  {
    day: "Tuesday 24th Dec",
    time: "09:00 - 13:00",
  },
  {
    day: "Wednesday 25th Dec",
    time: "Closed",
  },
  {
    day: "Thursday 26th Dec",
    time: "Closed",
  },
  {
    day: "Friday 27th Dec",
    time: "09:00 - 20:00",
  },
  {
    day: "Saturday 28th Dec",
    time: "10:00 - 18:00",
  },
  {
    day: "Sunday 29th Dec",
    time: "10:00 - 18:00",
  },
  {
    day: "Monday 30th Dec",
    time: "09:00 - 20:00",
  },
  {
    day: "Tuesday 31st Dec",
    time: "09:00 - 13:00",
  },
  {
    day: "Wednesday 1st Jan",
    time: "Closed",
  },
  {
    day: "Thursday 2nd Jan",
    time: "09:00 - 20:00",
  },
  {
    day: "Friday 3rd Jan",
    time: "09:00 - 20:00",
  },
];
