import React, { FC } from "react";

import { Spinner } from "@at/design-system-elements";
import loadable from "@loadable/component";

export const SkeletonProductPage = loadable(
  () => import("../skeleton/skeleton-product-page"),
) as FC;

export const ProductPage = loadable(
  () => import("../product-page/product-page"),
  {
    fallback: <SkeletonProductPage />,
  },
) as FC;

export const DigitalRetailingPage = loadable(
  () => import("../digital-retailing/digital-retailing-product-page"),
  {
    fallback: <SkeletonProductPage />,
  },
) as FC;

export const LeasingProductPage = loadable(
  () => import("../leasing/leasing-product-page"),
  {
    fallback: <Spinner />,
  },
) as FC;

export const LeasingEnquiryFormPage = loadable(
  () => import("../leasing/leasing-enquiry-form/leasing-enquiry-form-page"),
  {
    fallback: <Spinner />,
  },
) as FC;

export const LeasingEnquiryFormErrorPage = loadable(
  () =>
    import("../leasing/leasing-enquiry-form/leasing-enquiry-form-error-page"),
  {
    fallback: <Spinner />,
  },
) as FC;

export const LeasingEnquiryFormSuccessPage = loadable(
  () =>
    import("../leasing/leasing-enquiry-form/leasing-enquiry-form-success-page"),
  {
    fallback: <Spinner />,
  },
) as FC;

export const ContactDealer = loadable(
  () => import("../contact-dealer/contact-dealer"),
  {
    fallback: <Spinner />,
  },
) as FC;

export const ContactDealerSuccess = loadable(
  () => import("../contact-dealer/contact-dealer-success"),
  {
    fallback: <Spinner />,
  },
) as FC;

export const ContactDealerError = loadable(
  () => import("../email-form/email.form.error"),
  {
    fallback: <Spinner />,
  },
) as FC;
