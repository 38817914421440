import {
  getChannelCapitalised,
  getChannelSingular,
} from "@at/formatter-utils/lib/channel-utils/channel.util";
import { PageDataProps } from "@at/sauron-platform-core/lib/page/page-data";
import { Channel } from "@at/sauron-platform-core/lib/types";

const getPageDataProps = (channel: Channel): PageDataProps => {
  const channelCapitalised = getChannelCapitalised(channel);
  const channelSingular = getChannelSingular(channel);
  const titlePrefix =
    channel === Channel.cars
      ? `Auto Trader UK`
      : `Auto Trader ${channelCapitalised}`;

  const stockLevels: Record<Channel, string | null> = {
    [Channel.cars]: "400,000",
    [Channel.vans]: "30,000",
    [Channel.bikes]: "20,000",
    [Channel.motorhomes]: "7,000",
    [Channel.caravans]: "3,000",
    [Channel.trucks]: null,
    [Channel.plant]: null,
    [Channel.farm]: null,
    [Channel.cycles]: null,
  };
  const stockCount = stockLevels[channel];

  let metaDescription = `Find your next ${channelSingular} with Auto Trader UK, the official #1 site to buy and sell new and used ${channel}. `;
  metaDescription +=
    stockCount === null ? "" : `Over ${stockCount} ${channel} online. `;
  metaDescription += "Simple, easy, quick!";

  return {
    title: `${titlePrefix} - New and Used ${channelCapitalised} For Sale`,
    metaDescription,
  };
};

export const metaInfo: Record<Channel, PageDataProps> = {
  [Channel.cars]: getPageDataProps(Channel.cars),
  [Channel.vans]: getPageDataProps(Channel.vans),
  [Channel.bikes]: getPageDataProps(Channel.bikes),
  [Channel.motorhomes]: getPageDataProps(Channel.motorhomes),
  [Channel.caravans]: getPageDataProps(Channel.caravans),
  [Channel.trucks]: getPageDataProps(Channel.trucks),
  [Channel.plant]: getPageDataProps(Channel.plant),
  [Channel.farm]: getPageDataProps(Channel.farm),
  [Channel.cycles]: getPageDataProps(Channel.cycles),
};
