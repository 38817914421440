import {
  advertisingLocation,
  DEFAULT_LEASING_OPTION,
  PageState,
} from "./state";
import { LeaseContractType } from "../../../__generated__/graphql-types";
import { ELeasingBusinessType } from "../../../contexts/leasing/types/leasing.types";

export const searchInitialState: PageState = {
  leaseContractType: LeaseContractType.personal,
  leaseOption: DEFAULT_LEASING_OPTION,
  advertisingLocations: advertisingLocation,
  pageNumber: 1,
  showTermFilters: false,
  showSearchFilters: false,
  sort: "lease-best-value-asc",
  selectedBusinessType: ELeasingBusinessType.DEFAULT,
  leaseOfferTypes: null,
  page: "SEARCH",
};
