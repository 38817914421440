import { useAdvert } from "./use-advert";
import { useChannel } from "./use-channel";
import { useSellerPromise } from "./use-seller-promise";
import { FPADataQuery_search_Search_advert_Advert_reservation_AdvertReservation } from "../../../src/connected-components/hoc/query/__generated__/fpa-page-data.query";
import { AdvertReservationEligibility } from "../../__generated__/graphql-types";
import { getReservationInfo } from "../reservation/advert-reservation.utils";

export const useAdvertReservation = () => {
  const advert = useAdvert();
  const channel = useChannel();
  const { hasSellerPromise } = useSellerPromise();

  const notEligibleReservationDefault: Omit<
    FPADataQuery_search_Search_advert_Advert_reservation_AdvertReservation,
    "__typename"
  > = {
    status: null,
    eligibility: AdvertReservationEligibility.NOT_ELIGIBLE,
    feeCurrency: "GBP",
    feeInFractionalUnits: 19_900,
  };

  const showReservations = advert.condition !== "New";
  const reservationData = getReservationInfo({
    advertId: advert.id,
    channel,
    reservationData: showReservations
      ? advert.reservation || notEligibleReservationDefault
      : notEligibleReservationDefault,
    hasDrProduct:
      !!advert.sellerProducts?.includes("DIGITALRETAIL") ||
      !!advert.sellerProducts?.includes("DIGITALRETAILESTIMATE"),
    hasDigitalRetailingCapability:
      !!advert.capabilities?.digitalRetailing?.enabled,
    hasSellerPromise: hasSellerPromise,
  });
  return reservationData;
};
