import { gql } from "@at/sauron-platform-graphql";

const productPageQuery = gql`
  query ProductPageQuery($advertId: String!) {
    search {
      advert(advertId: $advertId) {
        id
        manufacturerColour
        imageList {
          images {
            url
            templated
            classificationTags {
              label
              category
            }
          }
        }
        generation {
          generationId
          bodyType
        }
        specification {
          derivative
          derivativeId
          make
          model
          trim
          fuel
          doors
          optionalFeatures {
            description
          }
          techData {
            transmission
            chargingData {
              fastestChargingPower
              fastestChargingDuration
              chargers {
                description
                fullCharge {
                  duration
                  endBatteryPercentage
                }
                topUp {
                  milesRange
                  duration
                }
                chargerLocation
                milesRangePerHourChargeTime
              }
            }
          }
        }
      }
    }
  }
`;

export default productPageQuery;
