import React, { FC } from "react";

import { ConnectedHeader } from "@at/connected-header";
import { HeaderProps } from "@at/design-system-collections";
import { useAppContext } from "@at/sauron-platform-core";
import { Channel } from "@at/sauron-platform-core/lib/types";

export const ATHeader: FC<HeaderProps> = (props) => {
  const {
    config: { env },
  } = useAppContext();

  return (
    <ConnectedHeader
      channel={props.channel as Channel}
      env={env}
      useNewSignIn
      legacyMargins={false}
    />
  );
};
