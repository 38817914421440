import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { TypeSmart } from "@at/design-system-foundation";
import { IconMail, IconShare, IconFacebook } from "@at/design-system-icons";
import { Modal, ModalVariant, useModal } from "@at/design-system-patterns";
import { getChannelSingular } from "@at/formatter-utils/lib/channel-utils/channel.util";
import {
  dataAttrGUI,
  dataAttrTestId,
} from "@at/formatter-utils/lib/data-attr-utils/data-attr-utils";
import { capitalsToTitleCase } from "@at/formatter-utils/lib/string-utils/string.utils";
import { Channel, useAppContext } from "@at/sauron-platform-core";
import { stringifyUrl } from "query-string";

import * as Styles from "./action-share.styles";
import SocialLink from "./social-link/social-link";
import { useAdvert } from "../../../../utils/hooks/use-advert";
import { useChannel } from "../../../../utils/hooks/use-channel";
import { useTracking } from "../../../../utils/tracking/use-tracking";
import { shareableSearchParams } from "../../../../utils/uri/uri-utils";

const getFacebookLink = (url: string) => {
  const shareUrl = stringifyUrl({
    url,
    query: { atmobcid: "soc6" },
  });

  // Documentation:
  // https://developers.facebook.com/docs/sharing/reference/feed-dialog/

  return stringifyUrl({
    url: "https://m.facebook.com/dialog/feed",
    query: {
      app_id: "1590619847851685",
      redirect_uri: shareUrl,
      link: shareUrl,
    },
  });
};

const getEmailLink = (
  url: string,
  channelSingularText: string,
  make: string,
  model: string,
) => {
  const channelTitle = capitalsToTitleCase(channelSingularText);
  const subject = `${channelTitle} for sale on Auto Trader`;

  const shareUrl = stringifyUrl({
    url,
    query: {
      utm_source: "triggered_emails",
      utm_medium: "Email",
      utm_campaign: "email_a_friend",
    },
  });

  let body = "";
  body += `Saw this '${make}`;
  body += !model || /unlisted/i.test(model) ? `'` : ` ${model}'`;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  body += ` on Auto Trader and thought you might be interested. Click this link to view the advert - ${shareUrl}`;

  return stringifyUrl({
    url: "mailto:",
    query: { subject, body },
  });
};

const AdvertShare = () => {
  const { isDesktop } = useAppContext();
  const advert = useAdvert();
  const channel = useChannel();
  const { trackEvent, trackModalEvent } = useTracking();
  const { isShowing, toggle } = useModal();
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState("");

  let channelSingularText = getChannelSingular(channel);

  if (channel === Channel.plant || channel === Channel.farm) {
    channelSingularText += " machinery";
  }

  useEffect(() => {
    let urlBase = "";
    urlBase += window.location ? window.location.origin : "";
    urlBase += location.pathname;

    const urlWithParams = stringifyUrl({
      url: urlBase,
      query: shareableSearchParams(location.search),
    });

    setCurrentUrl(urlWithParams);
  }, [location.pathname, location.search]);

  const shareFn = async function () {
    const shareDesc = {
      title: "Auto Trader",
      text: `I just found my dream ${channelSingularText}... Search for yours`,
      url: stringifyUrl({ url: currentUrl, query: { atmobcid: "soc5" } }),
    };

    if (!isDesktop() && window.navigator.share) {
      try {
        await window.navigator.share(shareDesc);
        trackEvent({ trackingLabel: "social share native" });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    } else {
      trackEvent({ trackingLabel: "share-expand" });
      toggle();
    }
  };

  const titleCaseMake = capitalsToTitleCase(advert.specification?.make || "");
  const titleCaseModel = capitalsToTitleCase(advert.specification?.model || "");

  const facebook = getFacebookLink(currentUrl);
  const email = getEmailLink(
    currentUrl,
    channelSingularText,
    titleCaseMake,
    titleCaseModel,
  );

  return (
    <>
      <TypeSmart as={"span"}>
        <Styles.AdvertActionButton
          onClick={shareFn}
          {...{
            [dataAttrGUI]: "share-advert-button",
            [dataAttrTestId]: "share-advert-button",
          }}
          aria-label="share advert"
        >
          <IconShare
            title={""}
            viewBox="0 0 22 22"
            customSize={{ width: 24, height: 24 }}
          />
        </Styles.AdvertActionButton>
      </TypeSmart>
      <Modal
        isShowing={isShowing}
        hasBackLink={true}
        closeText=" "
        variation={ModalVariant.MEDIUM}
        hideFn={toggle}
        additionalClass="custom-social-links-modal"
        portal={true}
        overlayName="social-share"
        trackingCallback={({ modalAction }) => {
          trackModalEvent({ trackingLabel: "social-share", modalAction });
        }}
      >
        <Styles.AdvertActionSocialContainer>
          <Styles.AdvertShareStyling>Share this with</Styles.AdvertShareStyling>
          <Styles.AdvertActionSocial>
            <SocialLink url={facebook} label="facebook-social">
              <IconFacebook title={""} additionalClass="facebook-link" />
            </SocialLink>
            <SocialLink url={email} label="email-social">
              <IconMail title={""} />
            </SocialLink>
          </Styles.AdvertActionSocial>
        </Styles.AdvertActionSocialContainer>
      </Modal>
    </>
  );
};

export default AdvertShare;
