import { getChannelSingular } from "@at/formatter-utils/lib/channel-utils/channel.util";

import { Channel } from "@at/sauron-platform-core/lib/types";

import {
  AdvertisingLocation,
  VatStatus,
} from "../../__generated__/graphql-types";
import { generateVanLabel } from "../finance/finance.utils";

export const advertisingLocations: Record<Channel, string[]> = {
  [Channel.cars]: ["at_cars", "at_profile_cars", "at_stock_cars"],
  [Channel.vans]: ["at_vans", "at_profile_vans", "at_stock_vans"],
  [Channel.bikes]: ["at_bikes", "at_profile_bikes", "at_stock_bikes"],
  [Channel.motorhomes]: [
    "at_motorhomes",
    "at_profile_motorhomes",
    "at_stock_motorhomes",
  ],
  [Channel.caravans]: [
    "at_caravans",
    "at_profile_caravans",
    "at_stock_caravans",
  ],
  [Channel.trucks]: ["at_trucks", "at_profile_trucks", "at_stock_trucks"],
  [Channel.plant]: ["at_plants", "at_profile_plants", "at_stock_plants"],
  [Channel.farm]: ["at_farm", "at_profile_farm", "at_stock_farm"],
  [Channel.cycles]: ["at_cycles"],
};

export const getAdvertisingLocationFromChannel = (
  channel: Channel,
): AdvertisingLocation => {
  switch (channel) {
    case Channel.bikes: {
      return AdvertisingLocation.at_bikes;
    }
    case Channel.caravans: {
      return AdvertisingLocation.at_caravans;
    }
    case Channel.motorhomes: {
      return AdvertisingLocation.at_motorhomes;
    }
    case Channel.vans: {
      return AdvertisingLocation.at_vans;
    }
    case Channel.trucks: {
      return AdvertisingLocation.at_trucks;
    }
    case Channel.plant: {
      return AdvertisingLocation.at_plants;
    }
    case Channel.farm: {
      return AdvertisingLocation.at_farm;
    }
    case Channel.cycles: {
      return AdvertisingLocation.at_cycles;
    }
    default: {
      return AdvertisingLocation.at_cars;
    }
  }
};

export const getCapitalizedSingularChannel = (channel: Channel): string => {
  if (!channel) return getCapitalizedSingularChannel(Channel.cars);
  else if (channel == Channel.cycles) return "Electric bike";
  const channelSingular = getChannelSingular(channel);
  return channelSingular[0]?.toUpperCase() + channelSingular.slice(1);
};

export const getChannelPriceLabel = (
  channel: Channel,
  vatStatus: VatStatus | null | undefined = null,
  isCrossover: boolean,
): string => {
  return channel === Channel.vans || isCrossover
    ? generateVanLabel(vatStatus)
    : `${getCapitalizedSingularChannel(channel)} price`;
};

export const isTruckPlantFarm = (channel: Channel): boolean => {
  return (
    channel === Channel.trucks ||
    channel === Channel.plant ||
    channel === Channel.farm
  );
};
