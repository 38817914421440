import { getChannelSingular } from "@at/formatter-utils/lib/channel-utils/channel.util";
import { Channel } from "@at/sauron-platform-core";
import { constructSearchQuery } from "@at/sauron-search-form-builder/lib/utils/submit-search.util";
import { SEARCH_URL_STORAGE_KEY } from "@at/window-utils/lib/local-storage-utils/local-storage-keys";
import { getItemLocalStorage } from "@at/window-utils/lib/local-storage-utils/local-storage.utils";
import { parse, ParsedQuery, stringifyUrl } from "query-string";

import { FPADataQuery_search_Search_advert_Advert } from "../../connected-components/hoc/query/__generated__/fpa-page-data.query";
import { isTruckPlantFarm } from "../../utils/channel/channel-utils";
import {
  getParsedQuerySingularValue,
  isFromDealerSearchResults,
  isFromWhatCar,
} from "../../utils/uri/uri-utils";

export const createSearchParamsForChannelFromLocalStorage = (
  channel: Channel,
) => {
  const value =
    getItemLocalStorage(`search.filters.${channel}.used`) || undefined;
  const queryFromStorage = value && JSON.parse(value);
  const searchUrl =
    queryFromStorage && constructSearchQuery(channel, queryFromStorage);
  return searchUrl && parse(searchUrl, { sort: false });
};

const getBackToDealerSearchResultsLink = (
  parsedQuery: ParsedQuery,
  channel: Channel,
): string => {
  const dealerSearchResultsUrl = getParsedQuerySingularValue(
    parsedQuery,
    "fromDealerSearchResults",
  );
  const searchPath = `/${getChannelSingular(channel)}-search`;

  try {
    if (dealerSearchResultsUrl && /^\/retailer/i.test(dealerSearchResultsUrl)) {
      return decodeURIComponent(dealerSearchResultsUrl);
    }
    return searchPath;
  } catch {
    return searchPath;
  }
};

const getBackToSearchResultsUrl = (
  channel: Channel,
  isFromDealerSearchResults: boolean,
) => {
  if (isTruckPlantFarm(channel) && isFromDealerSearchResults) {
    return `/${channel}/retailer/stock`;
  }
  if (channel == Channel.cycles) {
    return "/electric-bike-search";
  }
  return `/${getChannelSingular(channel)}-search`;
};

const deleteParams = (query: ParsedQuery<string>, params: string[]) => {
  for (const param of params) {
    delete query?.[param];
  }

  return query;
};

export const getBackToSearchResultsLink = (
  queryString: string | null,
  channel: Channel,
  advert: FPADataQuery_search_Search_advert_Advert,
  dealerId?: string,
): string | null => {
  const {
    id: advertId,
    specification: { make, model, fuel },
    condition,
  } = advert;

  if (!queryString) {
    queryString = getItemLocalStorage(SEARCH_URL_STORAGE_KEY) ?? null;

    if (queryString === null) {
      return null;
    }
  }
  if (queryString.trim().length === 0) {
    return null;
  }

  let parsedQuery = parse(queryString, { sort: false });

  if (parsedQuery.fromDealerSearchResults) {
    return getBackToDealerSearchResultsLink(parsedQuery, channel);
  }

  if (parsedQuery.oint) {
    // Don't show link if the page was Opened In a New Tab (oint) from search results
    return null;
  }

  if (parsedQuery.fromRetailerStore) {
    parsedQuery = createSearchParamsForChannelFromLocalStorage(channel);
  }

  const whatCarParams = isFromWhatCar(parsedQuery)
    ? {
        make,
        model,
        "fuel-type": fuel,
        "year-from": condition === "New" ? "new" : undefined,
      }
    : {};

  // Removes params which are not needed when returning to search results.
  const paramsToDelete = ["twcs", "searchId", "utm_source", "utm_medium"];
  parsedQuery = deleteParams(parsedQuery, paramsToDelete);

  return stringifyUrl(
    {
      url: getBackToSearchResultsUrl(
        channel,
        isFromDealerSearchResults(queryString),
      ),
      query: {
        ...parsedQuery,
        flrfc: 1,
        "calc-deposit": null,
        "calc-term": null,
        "calc-mileage": null,
        "calc-selected-product": null,
        retailerId: isFromDealerSearchResults(queryString)
          ? dealerId
          : undefined,
        ...whatCarParams,
      },
      fragmentIdentifier: advertId,
    },
    {
      sort: false,
      skipNull: true,
      skipEmptyString: true,
    },
  );
};
