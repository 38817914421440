/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  _Any: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
};

export enum ATAwardCategory {
  FINALIST = 'FINALIST',
  HIGHLY_RATED = 'HIGHLY_RATED',
  WINNER = 'WINNER'
}

export enum AdvertCapability {
  DIGITAL_RETAILING = 'DIGITAL_RETAILING',
  GUARANTEED_PART_EX = 'GUARANTEED_PART_EX'
}

export enum AdvertPaymentStatus {
  FAILED = 'FAILED',
  FAILED_INVALID_PAYMENT = 'FAILED_INVALID_PAYMENT',
  REQUIRES_AUTHENTICATION = 'REQUIRES_AUTHENTICATION',
  SUCCEEDED = 'SUCCEEDED'
}

export enum AdvertPreparePaymentStatus {
  ERROR_ADVERT_NOT_FOUND = 'ERROR_ADVERT_NOT_FOUND',
  ERROR_ADVERT_PACKAGE_CANNOT_BE_SET = 'ERROR_ADVERT_PACKAGE_CANNOT_BE_SET',
  ERROR_GENERAL = 'ERROR_GENERAL',
  SUCCESS = 'SUCCESS'
}

export type AdvertQuery = {
  readonly accelerationValue?: InputMaybe<Scalars['String']['input']>;
  readonly advertClassification?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly advertisingLocations?: InputMaybe<ReadonlyArray<InputMaybe<AdvertisingLocation>>>;
  readonly aggregatedTrim?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly annualTaxValue?: InputMaybe<Scalars['String']['input']>;
  readonly batteryChargeTimeValue?: InputMaybe<Scalars['String']['input']>;
  readonly batteryQuickChargeTimeValue?: InputMaybe<Scalars['String']['input']>;
  readonly batteryRangeValue?: InputMaybe<Scalars['String']['input']>;
  readonly bedroomLayout?: InputMaybe<Scalars['String']['input']>;
  readonly berth?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly bodyType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly bootSizeValues?: InputMaybe<BootSizeValue>;
  readonly cabType?: InputMaybe<Scalars['String']['input']>;
  readonly capabilities?: InputMaybe<ReadonlyArray<InputMaybe<AdvertCapability>>>;
  readonly clickAndCollectAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  readonly clickAndCollectOrHomeDeliveryAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  readonly co2EmissionValue?: InputMaybe<Scalars['String']['input']>;
  readonly colour?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly condition?: InputMaybe<ReadonlyArray<InputMaybe<Condition>>>;
  readonly dealerId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly deals?: InputMaybe<Scalars['Boolean']['input']>;
  readonly distance?: InputMaybe<Scalars['Int']['input']>;
  readonly doors?: InputMaybe<Scalars['String']['input']>;
  readonly doorsValue?: InputMaybe<Scalars['String']['input']>;
  readonly driverPosition?: InputMaybe<Scalars['String']['input']>;
  readonly drivetrain?: InputMaybe<Scalars['String']['input']>;
  readonly emissionClass?: InputMaybe<Scalars['String']['input']>;
  readonly emissionScheme?: InputMaybe<Scalars['String']['input']>;
  readonly endLayout?: InputMaybe<Scalars['String']['input']>;
  readonly excludeBodyType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly financeOption?: InputMaybe<Scalars['String']['input']>;
  readonly fuelConsumptionValue?: InputMaybe<Scalars['String']['input']>;
  readonly fuelType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly generationId?: InputMaybe<Scalars['String']['input']>;
  readonly hasImages?: InputMaybe<Scalars['Boolean']['input']>;
  readonly homeDeliveryAdverts?: InputMaybe<HomeDeliveryOptions>;
  readonly isManufacturerApproved?: InputMaybe<Scalars['Boolean']['input']>;
  readonly isSellerDefinedUsed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly keywords?: InputMaybe<Scalars['String']['input']>;
  readonly latLong?: InputMaybe<Scalars['String']['input']>;
  readonly leaseContractType?: InputMaybe<LeaseContractType>;
  readonly leaseOfferTypes?: InputMaybe<ReadonlyArray<InputMaybe<LeaseOfferType>>>;
  readonly leaseOption?: InputMaybe<Scalars['String']['input']>;
  readonly make?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly maxBatteryChargeTime?: InputMaybe<Scalars['Int']['input']>;
  readonly maxBatteryQuickChargeTime?: InputMaybe<Scalars['Int']['input']>;
  readonly maxBatteryRange?: InputMaybe<Scalars['Int']['input']>;
  readonly maxBootSize?: InputMaybe<Scalars['Int']['input']>;
  readonly maxEnginePower?: InputMaybe<Scalars['Int']['input']>;
  readonly maxEngineSize?: InputMaybe<Scalars['Float']['input']>;
  readonly maxEngineSizeCC?: InputMaybe<Scalars['Int']['input']>;
  readonly maxEngineSizeLitres?: InputMaybe<Scalars['String']['input']>;
  readonly maxExteriorLength?: InputMaybe<Scalars['Int']['input']>;
  readonly maxInsuranceGroup?: InputMaybe<Scalars['String']['input']>;
  readonly maxLeaseMonthlyPriceExVat?: InputMaybe<Scalars['Int']['input']>;
  readonly maxMTPLM?: InputMaybe<Scalars['Int']['input']>;
  readonly maxMileage?: InputMaybe<Scalars['Int']['input']>;
  readonly maxMonthlyPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly maxPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly maxSeats?: InputMaybe<Scalars['Int']['input']>;
  readonly maxSuppliedPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly maxYear?: InputMaybe<Scalars['String']['input']>;
  readonly minBatteryChargeTime?: InputMaybe<Scalars['Int']['input']>;
  readonly minBatteryQuickChargeTime?: InputMaybe<Scalars['Int']['input']>;
  readonly minBatteryRange?: InputMaybe<Scalars['Int']['input']>;
  readonly minBootSize?: InputMaybe<Scalars['Int']['input']>;
  readonly minEnginePower?: InputMaybe<Scalars['Int']['input']>;
  readonly minEngineSize?: InputMaybe<Scalars['Float']['input']>;
  readonly minEngineSizeCC?: InputMaybe<Scalars['Int']['input']>;
  readonly minEngineSizeLitres?: InputMaybe<Scalars['String']['input']>;
  readonly minExteriorLength?: InputMaybe<Scalars['Int']['input']>;
  readonly minInsuranceGroup?: InputMaybe<Scalars['String']['input']>;
  readonly minLeaseMonthlyPriceExVat?: InputMaybe<Scalars['Int']['input']>;
  readonly minMTPLM?: InputMaybe<Scalars['Int']['input']>;
  readonly minMileage?: InputMaybe<Scalars['Int']['input']>;
  readonly minMonthlyPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly minPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly minSeats?: InputMaybe<Scalars['Int']['input']>;
  readonly minSuppliedPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly minYear?: InputMaybe<Scalars['String']['input']>;
  readonly model?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly modelFamily?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly partExAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  readonly postalRegion?: InputMaybe<Scalars['String']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly sample?: InputMaybe<Scalars['Int']['input']>;
  readonly sellerType?: InputMaybe<Scalars['String']['input']>;
  readonly showWriteOff?: InputMaybe<Scalars['Boolean']['input']>;
  readonly style?: InputMaybe<Scalars['String']['input']>;
  readonly subStyle?: InputMaybe<Scalars['String']['input']>;
  readonly trailerAxleNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly transmission?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly vehicleCategory?: InputMaybe<Scalars['String']['input']>;
  readonly wheelbase?: InputMaybe<Scalars['String']['input']>;
  readonly withDigitalRetailing?: InputMaybe<Scalars['Boolean']['input']>;
  readonly withLocalFeaturedDealer?: InputMaybe<Scalars['Boolean']['input']>;
  readonly withPremiumListing?: InputMaybe<Scalars['Boolean']['input']>;
  readonly yearFrom?: InputMaybe<Scalars['String']['input']>;
};

export enum AdvertReservationEligibility {
  ELIGIBLE = 'ELIGIBLE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  RESERVED_ADVERTISER = 'RESERVED_ADVERTISER',
  RESERVED_AUTO_TRADER = 'RESERVED_AUTO_TRADER'
}

export enum AdvertisingLocation {
  at_bikes = 'at_bikes',
  at_caravans = 'at_caravans',
  at_cars = 'at_cars',
  at_cycles = 'at_cycles',
  at_farm = 'at_farm',
  at_lease_new_car = 'at_lease_new_car',
  at_lease_new_van = 'at_lease_new_van',
  at_lease_virtual_car = 'at_lease_virtual_car',
  at_lease_virtual_van = 'at_lease_virtual_van',
  at_motorhomes = 'at_motorhomes',
  at_new_cars = 'at_new_cars',
  at_new_cars_migration = 'at_new_cars_migration',
  at_plants = 'at_plants',
  at_profile_bikes = 'at_profile_bikes',
  at_profile_caravans = 'at_profile_caravans',
  at_profile_cars = 'at_profile_cars',
  at_profile_farm = 'at_profile_farm',
  at_profile_motorhomes = 'at_profile_motorhomes',
  at_profile_plants = 'at_profile_plants',
  at_profile_trucks = 'at_profile_trucks',
  at_profile_vans = 'at_profile_vans',
  at_trucks = 'at_trucks',
  at_vans = 'at_vans'
}

export type AssociatedVehicleQuery = {
  readonly advertType?: InputMaybe<Scalars['String']['input']>;
  readonly advertisingLocation?: InputMaybe<Scalars['String']['input']>;
  readonly annualTax?: InputMaybe<Scalars['String']['input']>;
  readonly bodyType?: InputMaybe<Scalars['String']['input']>;
  readonly colour?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly condition?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly dealerId: Scalars['String']['input'];
  readonly fuelConsumption?: InputMaybe<Scalars['String']['input']>;
  readonly fuelType?: InputMaybe<Scalars['String']['input']>;
  readonly make?: InputMaybe<Scalars['String']['input']>;
  readonly maxEngineSize?: InputMaybe<Scalars['Int']['input']>;
  readonly maxEngineSizeCC?: InputMaybe<Scalars['Int']['input']>;
  readonly maxInsuranceGroup?: InputMaybe<Scalars['String']['input']>;
  readonly maxManufacturedYear?: InputMaybe<Scalars['String']['input']>;
  readonly maxMileage?: InputMaybe<Scalars['Int']['input']>;
  readonly maxPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly minEngineSize?: InputMaybe<Scalars['Int']['input']>;
  readonly minEngineSizeCC?: InputMaybe<Scalars['Int']['input']>;
  readonly minManufacturedYear?: InputMaybe<Scalars['String']['input']>;
  readonly minMileage?: InputMaybe<Scalars['Int']['input']>;
  readonly minPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly model?: InputMaybe<Scalars['String']['input']>;
  readonly notAdvertId?: InputMaybe<Scalars['String']['input']>;
  readonly numberOfDoors?: InputMaybe<Scalars['Int']['input']>;
  readonly postCode?: InputMaybe<Scalars['String']['input']>;
  readonly sortOrder?: InputMaybe<Scalars['String']['input']>;
  readonly targetPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly transmission?: InputMaybe<Scalars['String']['input']>;
};

export enum AuctionStatus {
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  NOT_PROPOSED = 'NOT_PROPOSED',
  NOT_SOLD = 'NOT_SOLD',
  PROPOSAL_RETRACTED = 'PROPOSAL_RETRACTED',
  PROPOSED = 'PROPOSED',
  PROVISIONAL = 'PROVISIONAL',
  REJECTED = 'REJECTED',
  SOLD = 'SOLD',
  STARTED = 'STARTED'
}

export enum AuctionUpdateStatus {
  REJECTED = 'REJECTED'
}

export type AuctionVehicleDetailsAnswerResponseInput = {
  readonly answerKey: Scalars['String']['input'];
  readonly selected: Scalars['Boolean']['input'];
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

export enum AuctionVehicleDetailsAnswerType {
  MULTI = 'MULTI',
  MULTI_FT = 'MULTI_FT',
  SINGLE = 'SINGLE',
  SINGLE_FT = 'SINGLE_FT'
}

export type AuctionVehicleDetailsGroupResponseInput = {
  readonly groupKey: Scalars['String']['input'];
  readonly responses: ReadonlyArray<AuctionVehicleDetailsQuestionResponseInput>;
};

export type AuctionVehicleDetailsQuestionResponseInput = {
  readonly questionKey: Scalars['String']['input'];
  readonly responses: ReadonlyArray<AuctionVehicleDetailsAnswerResponseInput>;
};

export enum BootSizeValue {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

export enum ChargerLocationType {
  HOME = 'HOME',
  PUBLIC = 'PUBLIC'
}

export type ClickAndCollect = {
  readonly collectionLocationId: Scalars['String']['input'];
};

export type ClickAndCollectLeadInput = {
  readonly advertId: Scalars['String']['input'];
  readonly clickAndCollect: ClickAndCollect;
  readonly customer: MarketExtensionCustomer;
  readonly did: Scalars['String']['input'];
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<LeadMetadata>;
};

export enum CollectionLocationBadgeType {
  LOCATION = 'LOCATION',
  NEAREST = 'NEAREST'
}

export type CollectionLocationOptions = {
  readonly searchPostcode: Scalars['String']['input'];
};

export type CompetitionInput = {
  readonly address?: InputMaybe<Scalars['String']['input']>;
  readonly competitionData?: InputMaybe<Scalars['String']['input']>;
  readonly competitionName: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly marketingOptions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly town?: InputMaybe<Scalars['String']['input']>;
};

export enum Condition {
  NEARLY_NEW = 'NEARLY_NEW',
  NEW = 'NEW',
  USED = 'USED',
  VIRTUAL = 'VIRTUAL'
}

export type ConfirmAdvertPaymentInput = {
  readonly advertReference: Scalars['String']['input'];
  readonly orderCode: Scalars['String']['input'];
  readonly paymentIntentId: Scalars['String']['input'];
};

export type CreateAdvertInput = {
  readonly caravanType?: InputMaybe<Scalars['String']['input']>;
  readonly derivativeId?: InputMaybe<Scalars['String']['input']>;
  readonly firstRegistrationDate?: InputMaybe<Scalars['String']['input']>;
  readonly mileage?: InputMaybe<Scalars['Int']['input']>;
  readonly registration?: InputMaybe<Scalars['String']['input']>;
  readonly tpfFields?: InputMaybe<CreateAdvertInputTpfFields>;
  readonly vehicleType: Scalars['String']['input'];
};

export type CreateAdvertInputTpfFields = {
  readonly category: Scalars['String']['input'];
  readonly condition: PolaVehicleCondition;
  readonly subCategory?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePartExGuideInput = {
  readonly advertId: Scalars['String']['input'];
  readonly mileage: Scalars['String']['input'];
  readonly vrm: Scalars['String']['input'];
};

export enum DayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type DealBuilderLeadInput = {
  readonly advertisingLocation?: InputMaybe<Scalars['String']['input']>;
  readonly captchaResponse: Scalars['String']['input'];
  readonly channelName?: InputMaybe<Scalars['String']['input']>;
  readonly consent: Scalars['Boolean']['input'];
  readonly email: Scalars['String']['input'];
  readonly financeInputs?: InputMaybe<FinanceInputs>;
  readonly financeMonthlyPrice: Scalars['Boolean']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly geolocation?: InputMaybe<Scalars['String']['input']>;
  readonly guaranteedPartExId?: InputMaybe<Scalars['String']['input']>;
  readonly isLoggedIn?: InputMaybe<Scalars['Boolean']['input']>;
  readonly lastName: Scalars['String']['input'];
  readonly marketingOptions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly metadata: LeadMetadata;
  readonly odsJourney?: InputMaybe<Scalars['String']['input']>;
  readonly partExId?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly selectedDid?: InputMaybe<Scalars['String']['input']>;
  readonly sellerType?: InputMaybe<Scalars['String']['input']>;
  readonly stockReference: Scalars['String']['input'];
};

export type DealerEnquiryInput = {
  readonly captchaResponse?: InputMaybe<Scalars['String']['input']>;
  readonly consent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly leadData?: InputMaybe<Scalars['String']['input']>;
  readonly leadName?: InputMaybe<Scalars['String']['input']>;
  readonly marketingOptions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type DealerQuery = {
  readonly channel?: InputMaybe<Scalars['String']['input']>;
  readonly distance?: InputMaybe<Scalars['Int']['input']>;
  readonly latLong?: InputMaybe<Scalars['String']['input']>;
  readonly make?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly model?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly retailerLinkage?: InputMaybe<RetailerLinkageType>;
  readonly retailerTypes?: InputMaybe<ReadonlyArray<InputMaybe<RetailerType>>>;
  readonly specialistSeller?: InputMaybe<Scalars['String']['input']>;
};

export enum DealerReviewStatus {
  ACCEPTED = 'ACCEPTED',
  HOLDING = 'HOLDING',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
  SUBMITTED = 'SUBMITTED',
  SUSPENDED = 'SUSPENDED'
}

export enum DealerSort {
  DISTANCE = 'DISTANCE',
  NAME = 'NAME',
  WITH_RETAILER_REVIEWS = 'WITH_RETAILER_REVIEWS'
}

export type EnquiryInput = {
  readonly captchaResponse?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly leadData?: InputMaybe<Scalars['String']['input']>;
  readonly leadName: Scalars['String']['input'];
  readonly marketingOptions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum FinanceDisclaimerComponentType {
  LINK = 'LINK',
  TEXT = 'TEXT'
}

export type FinanceInputs = {
  readonly deposit?: InputMaybe<Scalars['Int']['input']>;
  readonly mileage?: InputMaybe<Scalars['Int']['input']>;
  readonly selectedProduct?: InputMaybe<Scalars['String']['input']>;
  readonly term?: InputMaybe<Scalars['Int']['input']>;
};

export enum FinanceProvider {
  DEALER = 'DEALER',
  ZUTO = 'ZUTO'
}

export type GuaranteedPartExchange = {
  readonly id: Scalars['String']['input'];
};

export type GuaranteedPartExchangeCustomer = {
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly telephone?: InputMaybe<Scalars['String']['input']>;
};

export type GuaranteedPartExchangeLeadInput = {
  readonly advertId: Scalars['String']['input'];
  readonly consent: Scalars['Boolean']['input'];
  readonly customer?: InputMaybe<GuaranteedPartExchangeCustomer>;
  readonly did: Scalars['String']['input'];
  readonly financeInputs?: InputMaybe<FinanceInputs>;
  readonly guaranteedPartExchange?: InputMaybe<GuaranteedPartExchange>;
  readonly marketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<LeadMetadata>;
};

export type HomeDeliveryLeadInput = {
  readonly advertId: Scalars['String']['input'];
  readonly customer: MarketExtensionCustomer;
  readonly did: Scalars['String']['input'];
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<LeadMetadata>;
};

export enum HomeDeliveryOptions {
  exclude = 'exclude',
  include = 'include',
  only = 'only'
}

export type InstantOfferCampaignInput = {
  readonly did: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly type: Scalars['String']['input'];
  readonly userId: Scalars['String']['input'];
  readonly vrm: Scalars['String']['input'];
};

export type LeadConsumerIdentity = {
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
  readonly signedInId?: InputMaybe<Scalars['String']['input']>;
  readonly visitorId?: InputMaybe<Scalars['String']['input']>;
};

export type LeadDeviceInformation = {
  readonly ipAddress?: InputMaybe<Scalars['String']['input']>;
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
  readonly userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type LeadInput = {
  readonly captchaResponse?: InputMaybe<Scalars['String']['input']>;
  readonly channel?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly leadData?: InputMaybe<Scalars['String']['input']>;
  readonly leadName: Scalars['String']['input'];
  readonly marketingOptions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type LeadMetadata = {
  readonly channel?: InputMaybe<Scalars['String']['input']>;
  readonly consumerIdentity?: InputMaybe<LeadConsumerIdentity>;
  readonly context?: InputMaybe<Scalars['String']['input']>;
  readonly deviceInformation?: InputMaybe<LeadDeviceInformation>;
  readonly experimentIds?: InputMaybe<Scalars['String']['input']>;
  readonly financeSearch?: InputMaybe<Scalars['Boolean']['input']>;
  readonly referrerHeader?: InputMaybe<Scalars['String']['input']>;
  readonly searchParameters?: InputMaybe<Scalars['String']['input']>;
  readonly searchPostcode?: InputMaybe<Scalars['String']['input']>;
  readonly site?: InputMaybe<Scalars['String']['input']>;
  readonly subType?: InputMaybe<Scalars['String']['input']>;
  readonly trackingUserId?: InputMaybe<Scalars['String']['input']>;
};

export enum LeaseContractType {
  business = 'business',
  personal = 'personal'
}

export type LeaseCustomer = {
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly preferredContact?: InputMaybe<PreferredContact>;
};

export enum LeaseOfferType {
  PROMOTED = 'PROMOTED',
  SPECIAL = 'SPECIAL'
}

export type LeaseOption = {
  readonly initialPayment: Scalars['Int']['input'];
  readonly months: Scalars['Int']['input'];
  readonly yearlyMiles: Scalars['Int']['input'];
};

export type LeasingLeadInput = {
  readonly captchaResponse: Scalars['String']['input'];
  readonly consent: Scalars['Boolean']['input'];
  readonly customer: LeaseCustomer;
  readonly did: Scalars['String']['input'];
  readonly leaseOption: LeaseOption;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly metadata: LeadMetadata;
  readonly stockReference: Scalars['String']['input'];
  readonly type: Scalars['String']['input'];
};

export enum LeasingProvider {
  Arval = 'Arval'
}

export type MakeModel = {
  readonly make?: InputMaybe<Scalars['String']['input']>;
  readonly model?: InputMaybe<Scalars['String']['input']>;
};

export type MarketExtensionCustomer = {
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MinMax = {
  readonly max?: InputMaybe<Scalars['Int']['input']>;
  readonly min?: InputMaybe<Scalars['Int']['input']>;
};

export enum MyAdvertsAdvertStatus {
  AUCTION = 'AUCTION',
  AUCTION_COMPLETED = 'AUCTION_COMPLETED',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  INCOMPLETE = 'INCOMPLETE',
  LIVE = 'LIVE',
  PROCESSING = 'PROCESSING',
  REBOOKED = 'REBOOKED',
  REMOVED = 'REMOVED'
}

export enum MyAdvertsSortBy {
  endDate = 'endDate',
  startDate = 'startDate'
}

export enum MyAdvertsSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum MyAdvertsStockPerformanceRating {
  ABOVE_AVERAGE = 'ABOVE_AVERAGE',
  BELOW_AVERAGE = 'BELOW_AVERAGE',
  EXCELLENT = 'EXCELLENT',
  NONE = 'NONE',
  POOR = 'POOR'
}

export type NewCarLeadInput = {
  readonly captchaResponse: Scalars['String']['input'];
  readonly channelName?: InputMaybe<Scalars['String']['input']>;
  readonly consent: Scalars['Boolean']['input'];
  readonly did: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly financeMonthlyPrice: Scalars['Boolean']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly leadName: Scalars['String']['input'];
  readonly logOperationalStats: Scalars['Boolean']['input'];
  readonly marketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly metadata: LeadMetadata;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly stockReference: Scalars['String']['input'];
};

export type NewsletterInput = {
  readonly email: Scalars['String']['input'];
  readonly journey: Scalars['String']['input'];
};

export enum NewsletterSubscriptionState {
  ExistingUserExistingSubscription = 'ExistingUserExistingSubscription',
  ExistingUserNewSubscription = 'ExistingUserNewSubscription',
  SoftRegExistingSubscription = 'SoftRegExistingSubscription',
  SoftRegNewSubscription = 'SoftRegNewSubscription'
}

export enum Platform {
  apps = 'apps',
  desktop = 'desktop',
  mobile = 'mobile'
}

export enum PolaAdvertCloneType {
  LIVE_EDIT = 'LIVE_EDIT',
  REBOOK = 'REBOOK'
}

export enum PolaAdvertImageRotation {
  DEGREES_90 = 'DEGREES_90',
  DEGREES_180 = 'DEGREES_180',
  DEGREES_270 = 'DEGREES_270'
}

export enum PolaAdvertStatus {
  AUCTION = 'AUCTION',
  AUCTION_COMPLETED = 'AUCTION_COMPLETED',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  INCOMPLETE = 'INCOMPLETE',
  LIVE = 'LIVE',
  PROCESSING = 'PROCESSING',
  REBOOKED = 'REBOOKED',
  REMOVED = 'REMOVED'
}

export enum PolaLiveEditPublishStatus {
  ERROR_GENERAL = 'ERROR_GENERAL',
  ERROR_PUBLISH_CONFLICT = 'ERROR_PUBLISH_CONFLICT',
  SUCCESS = 'SUCCESS'
}

export enum PolaVehicleCondition {
  NEW = 'NEW',
  USED = 'USED'
}

export type PreferredContact = {
  readonly preferredContactMethod: Scalars['String']['input'];
  readonly preferredContactTime?: InputMaybe<PreferredContactTime>;
};

export type PreferredContactTime = {
  readonly endTime?: InputMaybe<Scalars['String']['input']>;
  readonly startTime?: InputMaybe<Scalars['String']['input']>;
};

export type PrepareAdvertPaymentInput = {
  readonly advertReference: Scalars['String']['input'];
  readonly boostIds: ReadonlyArray<Scalars['Int']['input']>;
  readonly packageId: Scalars['Int']['input'];
};

export enum PriceIndicatorRating {
  FAIR = 'FAIR',
  GOOD = 'GOOD',
  GREAT = 'GREAT',
  HIGH = 'HIGH',
  LOW = 'LOW'
}

export type ReplyDataInput = {
  readonly dealerId: Scalars['String']['input'];
  readonly replyText: Scalars['String']['input'];
  readonly reviewGuid: Scalars['String']['input'];
};

export type ReportAdvertInput = {
  readonly advertId: Scalars['String']['input'];
  readonly captchaResponse?: InputMaybe<Scalars['String']['input']>;
  readonly channel?: InputMaybe<Scalars['String']['input']>;
  readonly dealerId?: InputMaybe<Scalars['String']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly reasonReported?: InputMaybe<Scalars['String']['input']>;
};

export type RepresentativeExampleInput = {
  readonly advertId: Scalars['String']['input'];
  readonly compareToIndexedQuote?: InputMaybe<Scalars['String']['input']>;
  readonly customerRef?: InputMaybe<Scalars['String']['input']>;
  readonly depositAmount: Scalars['String']['input'];
  readonly financeShowcaseOverride?: InputMaybe<Scalars['String']['input']>;
  readonly mileage: Scalars['String']['input'];
  readonly settlement?: InputMaybe<Scalars['String']['input']>;
  readonly term: Scalars['String']['input'];
  readonly termsType?: InputMaybe<Scalars['String']['input']>;
};

export type ReservationCompliance = {
  readonly marketing?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReservationConsumer = {
  readonly contactPreference?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly surname: Scalars['String']['input'];
  readonly telephone?: InputMaybe<Scalars['String']['input']>;
};

export type ReservationCreateCheckoutSession = {
  readonly channel?: InputMaybe<Scalars['String']['input']>;
  readonly compliance?: InputMaybe<ReservationCompliance>;
  readonly consumer: ReservationConsumer;
  readonly journey: ReservationJourney;
  readonly publicReference: Scalars['String']['input'];
  readonly retailerMessage: Scalars['String']['input'];
};

export enum ReservationErrorType {
  PROFANITY_CHECK_FAILED = 'PROFANITY_CHECK_FAILED',
  UNABLE_TO_GET_PAYMENT_SESSION = 'UNABLE_TO_GET_PAYMENT_SESSION',
  UPDATE_FAILED = 'UPDATE_FAILED'
}

export type ReservationJourney = {
  readonly platform: Scalars['String']['input'];
};

export enum ReservationPaymentStatus {
  AutoTraderInitiatedRefund = 'AutoTraderInitiatedRefund',
  ConsumerInitiatedRefund = 'ConsumerInitiatedRefund',
  ConsumerInitiatedTransfer = 'ConsumerInitiatedTransfer',
  Initiated = 'Initiated',
  InitiatedTransfer = 'InitiatedTransfer',
  RefundComplete = 'RefundComplete',
  ReservationHeld = 'ReservationHeld',
  RetailerInitiatedRefund = 'RetailerInitiatedRefund',
  RetailerInitiatedTransfer = 'RetailerInitiatedTransfer',
  RetailerPaid = 'RetailerPaid',
  TransferComplete = 'TransferComplete'
}

export type ReservationUpdateData = {
  readonly consumerEmail?: InputMaybe<Scalars['String']['input']>;
  readonly paymentStatus?: InputMaybe<ReservationPaymentStatus>;
  readonly reasonCode?: InputMaybe<Scalars['String']['input']>;
  readonly reasonText?: InputMaybe<Scalars['String']['input']>;
};

export type RetailerEnquiryInput = {
  readonly captchaResponse: Scalars['String']['input'];
  readonly consent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly did: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly marketingOptions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly preferredContact: PreferredContact;
};

export enum RetailerLinkageName {
  NETWORK_MULTI_LOCATION_STOCK_OWNER = 'NETWORK_MULTI_LOCATION_STOCK_OWNER',
  SINGLEVIEW_BILLING_OWNER = 'SINGLEVIEW_BILLING_OWNER'
}

export type RetailerLinkageType = {
  readonly name: RetailerLinkageName;
  readonly ownerId: Scalars['String']['input'];
};

export enum RetailerMediaType {
  Image = 'Image',
  Video = 'Video'
}

export type RetailerTermsAndConditionsInput = {
  readonly retailerId: Scalars['String']['input'];
  readonly termsType?: InputMaybe<Scalars['String']['input']>;
};

export enum RetailerType {
  new_bike = 'new_bike',
  new_car = 'new_car',
  used_bike = 'used_bike',
  used_car = 'used_car',
  used_caravan = 'used_caravan',
  used_farm = 'used_farm',
  used_motorhome = 'used_motorhome',
  used_plant = 'used_plant',
  used_truck = 'used_truck',
  used_van = 'used_van'
}

export type ReviewDataInput = {
  readonly dealerId: Scalars['String']['input'];
  readonly dealerName: Scalars['String']['input'];
  readonly description: Scalars['String']['input'];
  readonly emailAddress: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly originIp: Scalars['String']['input'];
  readonly rating: Scalars['Int']['input'];
  readonly title: Scalars['String']['input'];
};

export type SaveAdvertInput = {
  readonly advertId: Scalars['String']['input'];
  readonly isSaved?: InputMaybe<Scalars['Boolean']['input']>;
  readonly searchReferrerParams?: InputMaybe<SavedAdvertSearchReferrerParams>;
};

export type SavedAdvertSearchReferrerParams = {
  readonly latLong?: InputMaybe<Scalars['String']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly radius?: InputMaybe<Scalars['String']['input']>;
  readonly sort?: InputMaybe<Scalars['String']['input']>;
};

export enum SearchFacetName {
  acceleration_values = 'acceleration_values',
  advert_classification = 'advert_classification',
  aggregated_trim = 'aggregated_trim',
  annual_tax_values = 'annual_tax_values',
  battery_charge_time_values = 'battery_charge_time_values',
  battery_quick_charge_time_values = 'battery_quick_charge_time_values',
  battery_range_values = 'battery_range_values',
  bedroom_layout = 'bedroom_layout',
  berth = 'berth',
  body_type = 'body_type',
  boot_size_values = 'boot_size_values',
  cab_type = 'cab_type',
  capability = 'capability',
  click_and_collect_available = 'click_and_collect_available',
  click_and_collect_or_home_delivery_available = 'click_and_collect_or_home_delivery_available',
  co2_emission_values = 'co2_emission_values',
  colour = 'colour',
  distance = 'distance',
  /** @deprecated No longer supported */
  doors = 'doors',
  doors_values = 'doors_values',
  driver_position = 'driver_position',
  drivetrain = 'drivetrain',
  emission_scheme = 'emission_scheme',
  end_layout = 'end_layout',
  finance_option_deposits = 'finance_option_deposits',
  finance_option_mileages = 'finance_option_mileages',
  finance_option_terms = 'finance_option_terms',
  fuel_consumption_values = 'fuel_consumption_values',
  fuel_type = 'fuel_type',
  home_delivery_adverts = 'home_delivery_adverts',
  is_manufacturer_approved = 'is_manufacturer_approved',
  is_seller_defined_used = 'is_seller_defined_used',
  is_writeoff = 'is_writeoff',
  licence_type = 'licence_type',
  make = 'make',
  max_battery_charge_time = 'max_battery_charge_time',
  max_battery_quick_charge_time = 'max_battery_quick_charge_time',
  max_battery_range = 'max_battery_range',
  max_boot_size = 'max_boot_size',
  max_engine_power = 'max_engine_power',
  max_engine_size = 'max_engine_size',
  max_engine_size_cc = 'max_engine_size_cc',
  max_exterior_length = 'max_exterior_length',
  max_insurance_group = 'max_insurance_group',
  max_lease_monthly_price_ex_vat = 'max_lease_monthly_price_ex_vat',
  max_mileage = 'max_mileage',
  max_monthly_price = 'max_monthly_price',
  max_mtplm = 'max_mtplm',
  max_price = 'max_price',
  max_seats = 'max_seats',
  max_supplied_price = 'max_supplied_price',
  max_year_manufactured = 'max_year_manufactured',
  min_battery_charge_time = 'min_battery_charge_time',
  min_battery_quick_charge_time = 'min_battery_quick_charge_time',
  min_battery_range = 'min_battery_range',
  min_boot_size = 'min_boot_size',
  min_engine_power = 'min_engine_power',
  min_engine_size = 'min_engine_size',
  min_engine_size_cc = 'min_engine_size_cc',
  min_exterior_length = 'min_exterior_length',
  min_insurance_group = 'min_insurance_group',
  min_lease_monthly_price_ex_vat = 'min_lease_monthly_price_ex_vat',
  min_mileage = 'min_mileage',
  min_monthly_price = 'min_monthly_price',
  min_mtplm = 'min_mtplm',
  min_price = 'min_price',
  min_seats = 'min_seats',
  min_supplied_price = 'min_supplied_price',
  min_year_manufactured = 'min_year_manufactured',
  model = 'model',
  monthly_price = 'monthly_price',
  part_exchange_available = 'part_exchange_available',
  postal_region = 'postal_region',
  price = 'price',
  retailer_id = 'retailer_id',
  seller_type = 'seller_type',
  style = 'style',
  trailer_axle_number = 'trailer_axle_number',
  transmission = 'transmission',
  wheelbase = 'wheelbase',
  with_digital_retailing = 'with_digital_retailing',
  with_manufacturer_rrp_saving = 'with_manufacturer_rrp_saving'
}

export type SearchOptions = {
  readonly advertisingLocations?: InputMaybe<ReadonlyArray<InputMaybe<AdvertisingLocation>>>;
  readonly channel?: InputMaybe<Scalars['String']['input']>;
  readonly collectionLocationOptions?: InputMaybe<CollectionLocationOptions>;
  readonly financeOption?: InputMaybe<Scalars['String']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly quickQuote?: InputMaybe<Scalars['Boolean']['input']>;
  readonly replacePriceWithTotalPrice?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SellTileAdvertStatus {
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  INCOMPLETE = 'INCOMPLETE',
  LIVE = 'LIVE'
}

export type SoftRegistrationData = {
  readonly email: Scalars['String']['input'];
  readonly newsletterConsent: Scalars['Boolean']['input'];
};

export enum Sort {
  AGE_NEWEST_FIRST = 'AGE_NEWEST_FIRST',
  ATTRACTIVENESS_ASC = 'ATTRACTIVENESS_ASC',
  ATTRACTIVENESS_DESC = 'ATTRACTIVENESS_DESC',
  DISCOUNT_DESC = 'DISCOUNT_DESC',
  DISCOUNT_PERCENTAGE_DESC = 'DISCOUNT_PERCENTAGE_DESC',
  DISTANCE = 'DISTANCE',
  EXPERIMENTAL = 'EXPERIMENTAL',
  LEASE_BEST_VALUE_ASC = 'LEASE_BEST_VALUE_ASC',
  LEASE_PRICE_HIGH = 'LEASE_PRICE_HIGH',
  LEASE_PRICE_LOW = 'LEASE_PRICE_LOW',
  LEASE_RELEVANCE = 'LEASE_RELEVANCE',
  MAKE = 'MAKE',
  MILEAGE = 'MILEAGE',
  MILEAGE_ASC = 'MILEAGE_ASC',
  MILEAGE_DESC = 'MILEAGE_DESC',
  MODEL = 'MODEL',
  MONTHLY_PRICE_ASC = 'MONTHLY_PRICE_ASC',
  MONTHLY_PRICE_DESC = 'MONTHLY_PRICE_DESC',
  MOST_RECENT = 'MOST_RECENT',
  MOST_RECENT_WITH_IMAGES = 'MOST_RECENT_WITH_IMAGES',
  NATIONAL_DESIRABILITY_ASC = 'NATIONAL_DESIRABILITY_ASC',
  NATIONAL_DESIRABILITY_DESC = 'NATIONAL_DESIRABILITY_DESC',
  PLACEMENT_DESC = 'PLACEMENT_DESC',
  PLACEMENT_DESC_WITH_IMAGES = 'PLACEMENT_DESC_WITH_IMAGES',
  PREMIUM_DOWNGRADE = 'PREMIUM_DOWNGRADE',
  PREMIUM_UPGRADE = 'PREMIUM_UPGRADE',
  PRICE_HIGH = 'PRICE_HIGH',
  PRICE_LOW = 'PRICE_LOW',
  REGISTRATION_DATE_ASC = 'REGISTRATION_DATE_ASC',
  REGISTRATION_DATE_DESC = 'REGISTRATION_DATE_DESC',
  RELEVANCE = 'RELEVANCE',
  SPONSORED = 'SPONSORED',
  SPONSORED_SUPPLIED_PRICE = 'SPONSORED_SUPPLIED_PRICE',
  SUPPLIED_PRICE_ASC = 'SUPPLIED_PRICE_ASC',
  SUPPLIED_PRICE_DESC = 'SUPPLIED_PRICE_DESC',
  VERSION_DAYS_OLD = 'VERSION_DAYS_OLD',
  WITH_IMAGES_AND_DISCOUNT_FIRST = 'WITH_IMAGES_AND_DISCOUNT_FIRST',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  YEAR_DESC_PRICE_ASC = 'YEAR_DESC_PRICE_ASC'
}

export type SubmitAdvertPaymentInput = {
  readonly advertReference: Scalars['String']['input'];
  readonly cvcToken?: InputMaybe<Scalars['String']['input']>;
  readonly deviceAttributes?: InputMaybe<Scalars['String']['input']>;
  readonly paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  readonly saveCardDetails?: InputMaybe<Scalars['Boolean']['input']>;
  readonly usingSavedCard?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TermsAndConditionsInput = {
  readonly advertId: Scalars['String']['input'];
  readonly settlement?: InputMaybe<Scalars['String']['input']>;
  readonly termsType?: InputMaybe<Scalars['String']['input']>;
};

export type TextValidatorInput = {
  readonly text: Scalars['String']['input'];
};

export type TrackConversationStartInput = {
  readonly advertId: Scalars['String']['input'];
  readonly channel: Scalars['String']['input'];
  readonly clientSnowplowUserId?: InputMaybe<Scalars['String']['input']>;
  readonly conversation: Scalars['String']['input'];
  readonly method: Scalars['String']['input'];
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
  readonly userId?: InputMaybe<Scalars['String']['input']>;
};

export type TradeLeadInput = {
  readonly advertId?: InputMaybe<Scalars['String']['input']>;
  readonly advertPrice?: InputMaybe<Scalars['String']['input']>;
  readonly advertTitle?: InputMaybe<Scalars['String']['input']>;
  readonly advertVehicleColour?: InputMaybe<Scalars['String']['input']>;
  readonly advertVrm?: InputMaybe<Scalars['String']['input']>;
  readonly captchaResponse: Scalars['String']['input'];
  readonly channel?: InputMaybe<Scalars['String']['input']>;
  readonly did?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly leadName: Scalars['String']['input'];
  readonly marketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly marketingOptions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly metadata?: InputMaybe<LeadMetadata>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UnpublishAdvertInput = {
  readonly advertReference: Scalars['String']['input'];
  readonly reason: UnpublishAdvertReason;
};

export enum UnpublishAdvertReason {
  GAVE_FRIEND_OR_FAMILY = 'GAVE_FRIEND_OR_FAMILY',
  NEW_AD = 'NEW_AD',
  NO_SALE = 'NO_SALE',
  OTHER = 'OTHER',
  SOCIAL_DISTANCING = 'SOCIAL_DISTANCING',
  SOLD_AUCTION = 'SOLD_AUCTION',
  SOLD_AUTO_TRADER = 'SOLD_AUTO_TRADER',
  SOLD_COMPANY_USED_CARS = 'SOLD_COMPANY_USED_CARS',
  SOLD_DEALER = 'SOLD_DEALER',
  SOLD_EBAY = 'SOLD_EBAY',
  SOLD_FACEBOOK = 'SOLD_FACEBOOK',
  SOLD_FRIEND_OR_FAMILY = 'SOLD_FRIEND_OR_FAMILY',
  SOLD_GUMTREE = 'SOLD_GUMTREE',
  SOLD_PART_EX = 'SOLD_PART_EX',
  SOLD_PISTONHEADS = 'SOLD_PISTONHEADS',
  SOLD_PRIVATE_BUYER = 'SOLD_PRIVATE_BUYER',
  SOLD_SHPOCK = 'SOLD_SHPOCK',
  SOLD_WE_BUY_ANY_CAR = 'SOLD_WE_BUY_ANY_CAR'
}

export enum UpdateAdvertFieldAction {
  add = 'add',
  remove = 'remove',
  replace = 'replace'
}

export type UpdateAdvertFieldDetails = {
  readonly action: UpdateAdvertFieldAction;
  readonly index?: InputMaybe<Scalars['Int']['input']>;
  readonly name: UpdateAdvertFieldName;
  readonly value: Scalars['String']['input'];
};

export enum UpdateAdvertFieldName {
  advertFeature = 'advertFeature',
  allowContactByEmail = 'allowContactByEmail',
  axleConfiguration = 'axleConfiguration',
  bathroomType = 'bathroomType',
  bedLayout = 'bedLayout',
  bodyType = 'bodyType',
  cabType = 'cabType',
  capacityLiftingWeightUnit = 'capacityLiftingWeightUnit',
  capacityLiftingWeightValue = 'capacityLiftingWeightValue',
  chassisMake = 'chassisMake',
  chassisModel = 'chassisModel',
  colour = 'colour',
  derivative = 'derivative',
  derivativeId = 'derivativeId',
  description = 'description',
  driverPosition = 'driverPosition',
  email = 'email',
  emissionClass = 'emissionClass',
  endLayout = 'endLayout',
  engineCapacityCC = 'engineCapacityCC',
  enginePowerBHP = 'enginePowerBHP',
  enginePowerKW = 'enginePowerKW',
  enginePowerPS = 'enginePowerPS',
  externalHeightMillimetres = 'externalHeightMillimetres',
  externalLengthMillimetres = 'externalLengthMillimetres',
  externalWidthMillimetres = 'externalWidthMillimetres',
  firstName = 'firstName',
  firstRegistrationDate = 'firstRegistrationDate',
  fuelType = 'fuelType',
  generation = 'generation',
  grossTrailerWeightUnit = 'grossTrailerWeightUnit',
  grossTrailerWeightValue = 'grossTrailerWeightValue',
  grossVehicleWeightUnit = 'grossVehicleWeightUnit',
  grossVehicleWeightValue = 'grossVehicleWeightValue',
  hoursInOperation = 'hoursInOperation',
  imageRef = 'imageRef',
  lastName = 'lastName',
  make = 'make',
  maxPayloadKg = 'maxPayloadKg',
  maxReachUnit = 'maxReachUnit',
  maxReachValue = 'maxReachValue',
  mileage = 'mileage',
  miroKg = 'miroKg',
  model = 'model',
  motExpiryDate = 'motExpiryDate',
  numberOfBedrooms = 'numberOfBedrooms',
  numberOfBerths = 'numberOfBerths',
  numberOfOwners = 'numberOfOwners',
  operatingHeightUnit = 'operatingHeightUnit',
  operatingHeightValue = 'operatingHeightValue',
  operatingType = 'operatingType',
  operatingWidthUnit = 'operatingWidthUnit',
  operatingWidthValue = 'operatingWidthValue',
  postcode = 'postcode',
  price = 'price',
  primaryTelephone = 'primaryTelephone',
  seats = 'seats',
  secondaryTelephone = 'secondaryTelephone',
  serviceHistory = 'serviceHistory',
  subtitle = 'subtitle',
  trade = 'trade',
  transmissionType = 'transmissionType',
  trim = 'trim',
  vatStatus = 'vatStatus',
  videoUrl = 'videoUrl',
  volumeCapacityUnit = 'volumeCapacityUnit',
  volumeCapacityValue = 'volumeCapacityValue',
  wheelbaseType = 'wheelbaseType'
}

export type UpdateAdvertInput = {
  readonly advertFields: ReadonlyArray<UpdateAdvertFieldDetails>;
  readonly advertReference: Scalars['String']['input'];
};

export type UpdateAdvertTradeUserDetails = {
  readonly address1: Scalars['String']['input'];
  readonly address2?: InputMaybe<Scalars['String']['input']>;
  readonly county: Scalars['String']['input'];
  readonly postcode: Scalars['String']['input'];
  readonly telephone: Scalars['String']['input'];
  readonly townCity: Scalars['String']['input'];
  readonly tradingName: Scalars['String']['input'];
};

export type UpdateAdvertUserDetailsFields = {
  readonly firstName: Scalars['String']['input'];
  readonly isTrade: Scalars['Boolean']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly traderDetails?: InputMaybe<UpdateAdvertTradeUserDetails>;
};

export type UpdateAdvertUserDetailsInput = {
  readonly advertReference: Scalars['String']['input'];
  readonly fields: UpdateAdvertUserDetailsFields;
};

export enum UserLevel {
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_IN_RECENTLY = 'SIGNED_IN_RECENTLY'
}

export enum UserState {
  New = 'New',
  Registered = 'Registered',
  SoftReg = 'SoftReg'
}

export type UserStateInput = {
  readonly createSoftRegUser?: InputMaybe<Scalars['Boolean']['input']>;
  readonly email: Scalars['String']['input'];
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly journey?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly newsletter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserVehicleDatesInput = {
  readonly carInsuranceRenewalDate?: InputMaybe<VehicleDateInput>;
  readonly carWarrantyRenewalDate?: InputMaybe<VehicleDateInput>;
  readonly motDate?: InputMaybe<VehicleDateInput>;
  readonly nextServiceDate?: InputMaybe<VehicleDateInput>;
  readonly roadTaxRenewalDate?: InputMaybe<VehicleDateInput>;
};

export type UserVehicleInput = {
  readonly atDerivativeId: Scalars['String']['input'];
  readonly derivativeName: Scalars['String']['input'];
  readonly firstRegistration: Scalars['String']['input'];
  readonly make: Scalars['String']['input'];
  readonly mileage: Scalars['Int']['input'];
  readonly model: Scalars['String']['input'];
  readonly vrn: Scalars['String']['input'];
};

export type UserVehicleUpdateInput = {
  readonly atDerivativeId: Scalars['String']['input'];
  readonly derivativeName: Scalars['String']['input'];
  readonly mileage: Scalars['Int']['input'];
  readonly title: Scalars['String']['input'];
};

export enum VatStatus {
  INC_VAT = 'INC_VAT',
  NO_VAT = 'NO_VAT',
  PLUS_VAT = 'PLUS_VAT'
}

export enum VehicleChannel {
  bikes = 'bikes',
  caravans = 'caravans',
  cars = 'cars',
  cycles = 'cycles',
  farm = 'farm',
  motorhomes = 'motorhomes',
  plant = 'plant',
  trucks = 'trucks',
  vans = 'vans'
}

export enum VehicleCheckKey {
  COLOUR_CHANGED = 'COLOUR_CHANGED',
  EXPORTED = 'EXPORTED',
  HIGH_RISK = 'HIGH_RISK',
  IMPORTED = 'IMPORTED',
  MILEAGE_DISCREPANCY = 'MILEAGE_DISCREPANCY',
  PRIVATE_FINANCE = 'PRIVATE_FINANCE',
  REGISTRATION_CHANGED = 'REGISTRATION_CHANGED',
  SCRAPPED = 'SCRAPPED',
  STOLEN = 'STOLEN',
  TRADE_FINANCE = 'TRADE_FINANCE',
  WRITE_OFF_CATEGORY = 'WRITE_OFF_CATEGORY'
}

export enum VehicleCheckStatus {
  FAILED = 'FAILED',
  PASSED = 'PASSED',
  UNAVAILABLE = 'UNAVAILABLE'
}

export enum VehicleChecks {
  BASIC = 'BASIC',
  FULL = 'FULL',
  MISSING = 'MISSING'
}

export type VehicleDateInput = {
  readonly date: Scalars['String']['input'];
  readonly remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly showAutoDateMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VehicleDetailsQuestionInput = {
  readonly advertReference: Scalars['String']['input'];
  readonly groupKey: Scalars['String']['input'];
};

export type VehicleValuationEmailData = {
  readonly bodyType?: InputMaybe<Scalars['String']['input']>;
  readonly colour?: InputMaybe<Scalars['String']['input']>;
  readonly engineSize?: InputMaybe<Scalars['Int']['input']>;
  readonly freeToList?: InputMaybe<Scalars['Boolean']['input']>;
  readonly fuelType?: InputMaybe<Scalars['String']['input']>;
  readonly imageUrl?: InputMaybe<Scalars['String']['input']>;
  readonly make?: InputMaybe<Scalars['String']['input']>;
  readonly milage?: InputMaybe<Scalars['Int']['input']>;
  readonly model?: InputMaybe<Scalars['String']['input']>;
  readonly partExPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly transmission?: InputMaybe<Scalars['String']['input']>;
  readonly vrm?: InputMaybe<Scalars['String']['input']>;
  readonly year?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleValuationInput = {
  readonly derivativeId?: InputMaybe<Scalars['String']['input']>;
  readonly emailData?: InputMaybe<VehicleValuationEmailData>;
  readonly firstRegistrationDate?: InputMaybe<Scalars['String']['input']>;
  readonly odometerReadingMiles?: InputMaybe<Scalars['Int']['input']>;
  readonly sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
};
