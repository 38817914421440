import { Channel } from "@at/sauron-platform-core";

import { parseUrl } from "query-string";

import {
  FPADataQuery_search_Search_advert_Advert_dealer_Dealer,
  FPADataQuery_search_Search_advert_Advert as Advert,
} from "../../connected-components/hoc/query/__generated__/fpa-page-data.query";
import { isTruckPlantFarm } from "../channel/channel-utils";
import {
  getParsedQuerySingularValue,
  parseQueryStringValue,
} from "../uri/uri-utils";

export const SELLER_PROFILE_CHARACTER_LIMIT = 380;

export enum SellerType {
  TRADE = "Trade",
  PRIVATE = "Private",
}

const getSearchTrackingParams = () => {
  const { query } = parseUrl(window.location.href);
  const searchId = getParsedQuerySingularValue(query, "searchId");
  const journey = getParsedQuerySingularValue(query, "journey");

  let params = "";

  if (searchId) {
    const pageNumber = parseQueryStringValue(query, "page") || 1;
    params += `&searchId=${searchId}&page=${pageNumber}`;
  }

  if (journey) {
    params += `&journey=${journey}`;
  }

  return params;
};

export const getDefaultUrl = (
  retailerId: string,
  channel: Channel,
  dealer?: FPADataQuery_search_Search_advert_Advert_dealer_Dealer | null,
) => {
  let url = "/dealers";
  const searchTrackingParams = getSearchTrackingParams();
  const queryParams = `?channel=${channel}${searchTrackingParams}`;

  const county = dealer?.location?.county;
  const town = dealer?.location?.town;
  const name = dealer?.name;

  if (!dealer || !(county && town && name)) {
    return `/retailer/${retailerId}${queryParams}`;
  }
  url += `/${county}/${town}/${name}-${retailerId}`;

  return url.replace(/\s+/g, "-").toLowerCase() + queryParams;
};

export const getTPFDealerProfileUrl = (
  channel: Channel,
  retailerId: string,
) => {
  const searchTrackingParams = getSearchTrackingParams();

  switch (channel) {
    case Channel.plant: {
      return `https://www.autotrader.co.uk/plant/retailer/stock?advertising-location=at_plants&advertising-location=at_profile_plants&retailerId=${retailerId}${searchTrackingParams}`;
    }
    case Channel.farm: {
      return `https://www.autotrader.co.uk/farm/retailer/stock?advertising-location=at_farm&advertising-location=at_profile_farm&retailerId=${retailerId}${searchTrackingParams}`;
    }
    default: {
      return `https://www.autotrader.co.uk/trucks/retailer/stock?advertising-location=at_trucks&advertising-location=at_profile_trucks&retailerId=${retailerId}${searchTrackingParams}`;
    }
  }
};

export const getDealerProfileUrl = (
  advert: Advert,
  channel: Channel,
  dealer: FPADataQuery_search_Search_advert_Advert_dealer_Dealer | null,
): string | null => {
  const hasRetailerStoreProductCodes =
    advert.sellerProducts?.includes("PROFILE");
  const retailerId = dealer?.dealerId || advert.retailerId;

  if (!retailerId) {
    return null;
  }

  if (!hasRetailerStoreProductCodes) {
    if (isTruckPlantFarm(channel)) {
      return getTPFDealerProfileUrl(channel, retailerId);
    }
    return null;
  }

  return getDefaultUrl(retailerId, channel, dealer);
};
