import { getChannelSingular } from "@at/formatter-utils/lib/channel-utils/channel.util";
import { Channel } from "@at/sauron-platform-core/lib/types";

import { AdvertReservationEligibility } from "../../__generated__/graphql-types";
import { FPADataQuery_search_Search_advert_Advert_reservation_AdvertReservation } from "../../connected-components/hoc/query/__generated__/fpa-page-data.query";

export interface ReservationData {
  /**
   * Whether the vehicle is eligible for reservation but has not already been reserved
   */
  vehicleCanBeReserved: boolean;
  /**
   * Whether the vehicle has been reserved
   */
  vehicleIsReserved: boolean;
  /**
   * Whether the vehicle is **either** already reserved **or** can be reserved.
   *
   * This will be true if either `vehicleCanBeReserved` or `vehicleIsReserved` are true
   */
  reservationIsActive: boolean;
  reservationCtaDisplayText: ReservationCtaDisplayText;
}

interface ReservationCtaDisplayText {
  short: string;
  standard: string;
}

interface GetReservationDataParam {
  reservationData: FPADataQuery_search_Search_advert_Advert_reservation_AdvertReservation;
  channel: Channel;
  advertId: string;
  hasDrProduct: boolean;
  hasDigitalRetailingCapability: boolean;
  hasSellerPromise: boolean;
}

const getCtaDisplayTextReserved = (
  channel: Channel,
  channelSingular: string,
): ReservationCtaDisplayText => {
  switch (channel) {
    case Channel.cars:
    case Channel.vans:
    case Channel.bikes:
    case Channel.trucks: {
      return {
        standard: `This ${channelSingular} is reserved`,
        short: `This ${channelSingular} is reserved`,
      };
    }
    default: {
      return {
        standard: `Vehicle is reserved`,
        short: "Reserved",
      };
    }
  }
};

const getCtaDisplayTextEligible = (
  channel: Channel,
  channelSingular: string,
): ReservationCtaDisplayText => {
  switch (channel) {
    case Channel.cars:
    case Channel.vans:
    case Channel.bikes:
    case Channel.trucks: {
      return {
        short: `Reserve this ${channelSingular}`,
        standard: `Reserve this ${channelSingular}`,
      };
    }
    case Channel.caravans:
    case Channel.motorhomes: {
      return {
        standard: `Reserve ${channelSingular}`,
        short: `Reserve`,
      };
    }
    default: {
      return {
        standard: `Reserve this vehicle`,
        short: "Reserve",
      };
    }
  }
};

const getReservationCtaDisplayText = (
  vehicleIsReserved: boolean,
  channel: Channel,
): ReservationCtaDisplayText => {
  const channelSingular = getChannelSingular(channel);

  if (vehicleIsReserved) {
    return getCtaDisplayTextReserved(channel, channelSingular);
  }

  return getCtaDisplayTextEligible(channel, channelSingular);
};

export const getReservationInfo = ({
  reservationData,
  channel,
  hasDrProduct,
  hasDigitalRetailingCapability,
  hasSellerPromise,
}: GetReservationDataParam): ReservationData => {
  const isADRCustomerOnTheDRPageWithEligibleStock = () => {
    return hasDigitalRetailingCapability;
  };
  const isNotADRCustomerButHasReservations = () => {
    return hasSellerPromise && !hasDrProduct;
  };

  const vehicleIsReserved =
    reservationData.eligibility ===
      AdvertReservationEligibility.RESERVED_AUTO_TRADER ||
    reservationData.eligibility ===
      AdvertReservationEligibility.RESERVED_ADVERTISER;
  const vehicleCanBeReserved =
    reservationData.eligibility === AdvertReservationEligibility.ELIGIBLE;
  const reservationIsActive =
    (isADRCustomerOnTheDRPageWithEligibleStock() ||
      isNotADRCustomerButHasReservations()) &&
    reservationData.eligibility !== AdvertReservationEligibility.NOT_ELIGIBLE;

  const reservationCtaDisplayText = getReservationCtaDisplayText(
    vehicleIsReserved,
    channel,
  );

  return {
    vehicleCanBeReserved,
    vehicleIsReserved,
    reservationIsActive,
    reservationCtaDisplayText,
  };
};
