import { usePageState } from "@at/sauron-platform-core";

import { useAdvert } from "./use-advert";
import { useDealer } from "./use-dealer";
import { retailerHasSellerPromise } from "../seller-promise/seller-promise.utils";

export const useSellerPromise = () => {
  const advert = useAdvert();
  const dealer = useDealer();
  const {
    state: { channel },
  } = usePageState();

  const sellerPromiseData = dealer?.servicesOffered?.sellerPromise;

  const hasSellerPromiseData =
    !!sellerPromiseData?.daysMoneyBackGuarantee &&
    !!sellerPromiseData?.monthlyWarranty &&
    sellerPromiseData?.moneyBackRemoteOnly !== null;

  const hasSellerPromise =
    retailerHasSellerPromise(channel, advert.condition) && hasSellerPromiseData;

  return { hasSellerPromise, hasSellerPromiseData };
};
