import { gql } from "@at/sauron-platform-graphql";

export const leaseOffersQuery = gql`
  query LeaseOffersQuery(
    $advertId: String!
    $leaseOption: String
    $leaseContractType: LeaseContractType
  ) {
    search {
      advert(
        advertId: $advertId
        leaseOption: $leaseOption
        leaseContractType: $leaseContractType
      ) {
        leasingOffers {
          depositMonths
          termMonths
          totalMileage
          monthlyPrice
          monthlyPriceExVat
          colour
          upholstery
          deliveryEstimate
          vehicleId
          funderId
          stockBatchId
          deliveryEstimateDays
        }
      }
    }
  }
`;
