import { ELeasingBusinessType } from "../../../contexts/leasing/types/leasing.types";

export const getBusinessType = (selectedBusinessType: ELeasingBusinessType) => {
  switch (selectedBusinessType) {
    case ELeasingBusinessType.SOLE_TRADER: {
      return "SOLE_TRADER";
    }
    case ELeasingBusinessType.LIMITED_LIABILITY_PARTNERSHIP: {
      return "LIMITED_LIABILITY_PARTNERSHIP";
    }
    case ELeasingBusinessType.LIMITED_COMPANY: {
      return "LIMITED_COMPANY";
    }
    default: {
      return;
    }
  }
};
