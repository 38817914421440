import React from "react";

import { withLeadPageData } from "../../../connected-components/hoc/lead-page.hoc";
import { withQueryClient } from "../../../connected-components/hoc/query-client.hoc";
import { ContactDealer } from "../loadables";

const ContactDealerWithoutLayoutPage = () => {
  const PageWithData = withQueryClient(withLeadPageData(ContactDealer));

  return <PageWithData />;
};

export default ContactDealerWithoutLayoutPage;
