/* eslint-disable react/display-name */
import React, { createContext, FC } from "react";

import { useLocation, useParams } from "react-router-dom";

import { ErrorDetail } from "@at/connected-utils/lib/error/error-detail";

import { Advert } from "@at/product-page-aggregator";
import { useQuery } from "@at/sauron-platform-graphql";
import { parse } from "query-string";

import { Channel } from "../../__generated__/fed-graphql-types";
import {
  AdvertAndDealerByIdWithClickAndCollectLocationsQuery,
  AdvertAndDealerByIdWithClickAndCollectLocationsQuery_search_Search_advert_Advert,
  AdvertAndDealerByIdWithClickAndCollectLocationsQuery_search_Search_dealer_Dealer,
  AdvertAndDealerByIdWithClickAndCollectLocationsQueryVariables,
} from "../../connected/query/__generated__/advert-and-dealer-by-id-with-click-and-collection-locations.query";

import { advertAndDealerByIdWithClickAndCollectionLocationsGql } from "../../connected/query/advert-and-dealer-by-id-with-click-and-collection-locations.query";
// import { METype } from "../../constants/market-extension.type";
import SkeletonLeadFormPage from "../../pages/skeleton/skeleton-lead-form-page";
import { useFetchAdvert } from "../../utils/hooks/use-fetch-advert";
import { mapToUtagData } from "../../utils/tracking/tracking-data-utils";
import { useTracking } from "../../utils/tracking/use-tracking";
interface MatchParams {
  advertId: string;
  dealerId: string;
}

export interface LeadContextProps {
  advert: AdvertAndDealerByIdWithClickAndCollectLocationsQuery_search_Search_advert_Advert | null;
  restAdvert?: Advert;
  dealer: AdvertAndDealerByIdWithClickAndCollectLocationsQuery_search_Search_dealer_Dealer | null;
  channel: Channel;
}
export const LeadContext = createContext<LeadContextProps>({
  advert: null,
  restAdvert: {} as unknown as Advert,
  dealer: null,
  channel: Channel.cars,
});

const channelFromAdvertisingLocation = (
  advertisingLocation: string | string[] | null,
) => {
  if (advertisingLocation?.includes("at_vans")) {
    return Channel.vans;
  } else if (advertisingLocation?.includes("at_bikes")) {
    return Channel.bikes;
  } else if (advertisingLocation?.includes("at_motorhomes")) {
    return Channel.motorhomes;
  } else if (advertisingLocation?.includes("at_caravans")) {
    return Channel.caravans;
  } else if (advertisingLocation?.includes("at_trucks")) {
    return Channel.trucks;
  } else if (advertisingLocation?.includes("at_plants")) {
    return Channel.plant;
  } else if (advertisingLocation?.includes("at_farm")) {
    return Channel.farm;
  } else if (advertisingLocation?.includes("at_cycles")) {
    return Channel.cycles;
  }
  return Channel.cars;
};

export const withLeadPageData = (WC: FC) => () => {
  const params = useParams<MatchParams>();
  const location = useLocation();
  const queryParams = location.search ? parse(location.search) : {};

  const advertisingLocation = parse(location.search || "")[
    "advertising-location"
  ];

  const channel = channelFromAdvertisingLocation(advertisingLocation);

  const postcode = Array.isArray(queryParams.postcode)
    ? queryParams.postcode[0]
    : queryParams.postcode;
  const queryType = advertAndDealerByIdWithClickAndCollectionLocationsGql;

  const options = {
    variables: {
      advertId: params.advertId,
      dealerId: params.dealerId,
      searchOptions: {
        collectionLocationOptions: {
          searchPostcode: postcode || "",
        },
      },
    } satisfies AdvertAndDealerByIdWithClickAndCollectLocationsQueryVariables,
  };

  const { data, error, loading } =
    useQuery<AdvertAndDealerByIdWithClickAndCollectLocationsQuery>(
      queryType,
      options,
    );

  const advert = data?.search.advert;
  const dealer = data?.search.dealer;

  const {
    data: restAdvert,
    isLoading: restLoading,
    error: restError,
  } = useFetchAdvert();

  if (restError) {
    // eslint-disable-next-line no-console
    console.error("Error when calling PPA", restError);
  }

  const { trackPageViewEvent } = useTracking({
    pageName: `${channel}:search:email-seller`,
    utagData: mapToUtagData(`${channel}:search:email-seller`, advert, channel),
  });

  if (loading || restLoading) {
    return <SkeletonLeadFormPage />;
  }

  if (advert && restAdvert && dealer) {
    trackPageViewEvent();
    return (
      <LeadContext.Provider value={{ advert, restAdvert, dealer, channel }}>
        <WC />
      </LeadContext.Provider>
    );
  }
  return <ErrorDetail error={error} />;
};
