import { Link } from "@at/design-system-elements";
import { TypeInsignia, ThemeSelectors } from "@at/design-system-foundation";
import styled from "styled-components";

interface AdvertActionButtonProps {
  save?: boolean;
}

export const AdvertActionButton = styled(Link).attrs({
  renderAsButton: true,
})<AdvertActionButtonProps>`
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  margin-left: ${({ theme }) => ThemeSelectors.getGroupThreeSpacing(theme)};

  svg {
    display: inline-block;
    vertical-align: middle;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 0 ${({ theme }) => ThemeSelectors.getGroupOneSpacing(theme)};
    ${({ theme, save }) =>
      save
        ? `fill: ${ThemeSelectors.getLegacyRed(theme)}`
        : `fill: currentColor`};
    cursor: pointer;

    path {
      fill: currentColor;
    }

    &:hover {
      ${({ theme, save }) =>
        save
          ? `fill: ${ThemeSelectors.getLegacyRed(theme)}`
          : `fill: currentColor;`}
    }
  }

  .save-advert path {
    ${({ theme, save }) =>
      save
        ? `fill: ${ThemeSelectors.getLegacyRed(theme)}`
        : `fill: ${ThemeSelectors.getActionColor(theme)}`};
    &:hover {
      ${({ theme, save }) =>
        save
          ? `fill: ${ThemeSelectors.getLegacyRed(theme)}`
          : `fill: ${ThemeSelectors.getActionHoverColor(theme)};`}
    }
  }
`;

export const AdvertActionSocialContainer = styled.div`
  display: block;
  width: 60%;
  height: 100%;
  justify-content: center;
  text-align: center;
  padding: 32px 0;
  margin: auto;
`;
export const AdvertShareStyling = styled(TypeInsignia).attrs({
  as: "div",
  weight: 500,
})`
  justify-content: center;
  color: #313c53;
`;

export const AdvertActionSocial = styled.section`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => ThemeSelectors.getGroupFiveSpacing(theme)};
  margin: calc(${({ theme }) => ThemeSelectors.getGridUnit(theme)} * 4) 0;

  svg {
    display: block;
    width: 26px;
    height: 26px;

    &.facebook-link path:last-of-type {
      fill: ${({ theme }) => ThemeSelectors.getWhite(theme)};
    }

    path {
      fill: ${({ theme }) => ThemeSelectors.getActionColor(theme)};
      &:hover {
        fill: ${({ theme }) => ThemeSelectors.getActionHoverColor(theme)};
      }
    }
  }
`;
