import { useLocation } from "react-router-dom";

import {
  InteractionType,
  useATPageViewEvent,
  useATTrackEvent,
  useATTrackInteraction,
  useATTrackPrestigeEvent,
  UtagDimensions,
} from "@at/consumer-web-snowplow";

import { ATPageViewCallbackArgs } from "@at/consumer-web-snowplow/lib/interaction-tracking/types/ATInteractionTracking.types";
import { AccordionItemPropsTypes } from "@at/design-system-patterns";
import { PrestigeEventName } from "@at/track-core";
import { Entities } from "@at/track-types";

import { getComponentViewedEntity } from "./snowplow-utils";
import { getFPAPageViewEventEntities } from "./tracking-data-utils";
import { FPADataQuery_search_Search_advert_Advert } from "../../connected-components/hoc/query/__generated__/fpa-page-data.query";
import { useLeasingStateContext } from "../../contexts/leasing/context/leasing.context";
import { useAdvert } from "../hooks/use-advert";
import { useChannel } from "../hooks/use-channel";
import { getLeasingFPAPageViewEventEntities } from "../tracking-util";

export interface TrackingProps {
  trackingLabel: string;
  action?: InteractionType;
  extraEntities?: Entities;
  replaceEntities?: Entities;
  prestigeEventName?: PrestigeEventName;
}

interface AccordionTrackingProps {
  row: {
    rowIndex: number;
    isExpanded: boolean;
  };
  data: AccordionItemPropsTypes[];
  extraEntities?: Entities;
}

interface ModalTrackingProps {
  trackingLabel: string;
  modalAction: string;
  extraEntities?: Entities;
}

interface PageViewTrackingProps {
  extraEntities?: Entities;
  utagDimensions?: UtagDimensions;
  replaceEntities?: Entities;
}

interface ComponentViewedTrackingProps {
  extraEntities?: Entities;
}
interface SearchEventTrackingProps {
  extraEntities?: Entities;
}
interface UseTracking {
  trackEvent: (props: TrackingProps) => void;
  trackAccordionEvent: (props: AccordionTrackingProps) => void;
  trackModalEvent: (props: ModalTrackingProps) => void;
  trackPageViewEvent: (props?: PageViewTrackingProps) => void;
  trackComponentViewedEvent: (props: ComponentViewedTrackingProps) => void;
  trackSearchEvent: (props: SearchEventTrackingProps) => void;
}

interface UseTrackingProps {
  pageName?: string;
  utagData?: UtagDimensions;
  domainEntityPageName?: string;
  pageViewAdvert?: FPADataQuery_search_Search_advert_Advert;
}

const removeMatchingEntities = (
  entities: Entities,
  entitiesToReplace: Entities,
): Entities => {
  const schemaKeysSet = new Set(
    entitiesToReplace.map((entity) => entity.SCHEMA_KEY),
  );
  return entities.filter((entity) => !schemaKeysSet.has(entity.SCHEMA_KEY));
};

export const useTracking = ({
  pageName,
  utagData,
  domainEntityPageName,
  pageViewAdvert,
}: UseTrackingProps = {}): UseTracking => {
  const advert = useAdvert();
  const channel = useChannel();
  const { search } = useLocation();

  const state = useLeasingStateContext();

  const trackingEntities = advert.id
    ? getFPAPageViewEventEntities(
        pageViewAdvert ?? advert,
        channel,
        domainEntityPageName ?? "fpa",
        search,
      )
    : state.selectedLeaseOffer
    ? getLeasingFPAPageViewEventEntities(state)
    : [];

  // Page view tracking
  const { firePageView } = useATPageViewEvent();
  // Interaction tracking
  const trackingCallback = useATTrackInteraction();
  const trackingEventCallback = useATTrackEvent();
  const prestigeTrackingCallback = useATTrackPrestigeEvent();

  const componentViewedEntity = getComponentViewedEntity("gallery-image");

  const trackEvent = ({
    trackingLabel,
    action = "click",
    extraEntities,
    replaceEntities,
    prestigeEventName,
  }: TrackingProps) => {
    const newEntities = extraEntities
      ? [...trackingEntities, ...extraEntities]
      : trackingEntities;

    if (prestigeEventName) {
      prestigeTrackingCallback({
        prestigeEventName,
        customiseEntities: (contextEntities: Entities) => {
          if (replaceEntities) {
            const filteredEntities = removeMatchingEntities(
              newEntities,
              replaceEntities,
            );
            return [
              ...contextEntities,
              ...filteredEntities,
              ...replaceEntities,
            ];
          }
          return [...contextEntities, ...newEntities];
        },
      });
    }

    return trackingCallback({
      trackingLabel,
      interaction: action,
      /**  Note: this spread entities *will* create duplicates when passed as they are not filtered */
      customiseEntities: (contextEntities: Entities) => {
        if (replaceEntities) {
          const filteredEntities = removeMatchingEntities(
            newEntities,
            replaceEntities,
          );
          return [...contextEntities, ...filteredEntities, ...replaceEntities];
        }
        return [...contextEntities, ...newEntities];
      },
    });
  };

  const trackAccordionEvent = ({
    row,
    data,
    extraEntities,
  }: AccordionTrackingProps) => {
    const { rowIndex, isExpanded } = row;
    const trackingLabel = data[rowIndex].trackingLabel;

    return trackEvent({
      trackingLabel: `${trackingLabel}-${
        isExpanded ? "expanded" : "collapsed"
      }`,
      extraEntities,
    });
  };

  const trackModalEvent = ({
    trackingLabel,
    modalAction,
    extraEntities,
  }: ModalTrackingProps) => {
    return trackEvent({
      trackingLabel: `${trackingLabel}-${modalAction}-modal`,
      action: "view",
      extraEntities,
    });
  };

  const trackPageViewEvent = ({
    extraEntities,
    utagDimensions,
    replaceEntities,
  }: PageViewTrackingProps = {}) => {
    const defaultPageViewParams = { utagDimensions };
    const extraPageViewParams: ATPageViewCallbackArgs = extraEntities
      ? {
          utagDimensions,
        }
      : defaultPageViewParams;

    return firePageView({
      utagDimensions: utagData,
      pageName,
      channel,
      customiseEntities: (contextEntities: Entities) => {
        const currentEntities = extraEntities
          ? [...trackingEntities, ...extraEntities]
          : trackingEntities;
        if (replaceEntities) {
          const filteredEntities = removeMatchingEntities(
            currentEntities,
            replaceEntities,
          );

          return [...contextEntities, ...filteredEntities, ...replaceEntities];
        }

        return [...contextEntities, ...currentEntities];
      },
      ...extraPageViewParams,
    });
  };

  const trackComponentViewedEvent = ({
    extraEntities,
  }: ComponentViewedTrackingProps) => {
    const entities = extraEntities
      ? [...trackingEntities, ...extraEntities]
      : trackingEntities;

    const galleryImagesEntities: Entities = entities.filter((obj) => {
      return obj.SCHEMA_KEY === "GalleryImage102";
    });

    return trackingEventCallback({
      fireEventEntity: componentViewedEntity,
      /**  Note: this spread entities *will* create duplicates when passed as they are not filtered */
      customiseEntities: (contextEntities: Entities) => [
        ...contextEntities,
        ...entities,
        ...galleryImagesEntities,
      ],
    });
  };

  const trackSearchEvent = ({ extraEntities }: SearchEventTrackingProps) => {
    return trackingEventCallback({
      fireEventEntity: { SCHEMA_KEY: "SearchEvent100" },
      /**  Note: this spread entities *will* create duplicates when passed as they are not filtered */
      customiseEntities: (entities: Entities) => [
        ...entities,
        ...trackingEntities,
        ...(extraEntities ?? []),
      ],
    });
  };

  return {
    trackEvent,
    trackAccordionEvent,
    trackModalEvent,
    trackPageViewEvent,
    trackComponentViewedEvent,
    trackSearchEvent,
  };
};
