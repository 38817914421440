import {
  ELeasingBusinessType,
  LeasingModals,
  LeasingState,
  ProductPageStockFilters,
} from "../types/leasing.types";

export const initState = {
  selectedStockFilter: ProductPageStockFilters.IN_STOCK,
  selectedBusinessType: ELeasingBusinessType.DEFAULT,
  businessTypeError: false,
  leasingModal: {
    show: false,
    type: LeasingModals.NONE,
    title: "",
  },
} satisfies Partial<LeasingState>;
