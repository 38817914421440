import React from "react";

import { AutoTraderSupportChatScript } from "@at/connected-at-support-chat-script/lib/at-support-chat-script";
import { useUserState } from "@at/connected-signin";
import { withLayout } from "@at/sauron-platform-core/lib/layout/layout.hoc";

import { layoutOptions } from "../../../utils/page-wrapper/page-wrapper-utils";
import { LeasingProductPage } from "../loadables";

const LeasingPage = () => {
  const [user] = useUserState();

  const WrappedComponent = withLayout(LeasingProductPage, layoutOptions);
  return (
    <>
      <AutoTraderSupportChatScript
        useZendeskOnlyIfUserInTestBucketOf={"zl1"}
        userIsSignedIn={user.userSignedIn}
      />
      <WrappedComponent />
    </>
  );
};
export default LeasingPage;
