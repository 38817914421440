import React from "react";
import { Helmet } from "react-helmet-async";

import { useMediaQuery } from "react-responsive";

import { FPADataQuery_search_Search_advert_Advert_imageList_ImageList_images_ImageUrl } from "../../connected-components/hoc/query/__generated__/fpa-page-data.query";

export const PreloadGalleryPageImageLinks = ({
  images,
}: {
  images:
    | readonly FPADataQuery_search_Search_advert_Advert_imageList_ImageList_images_ImageUrl[]
    | undefined;
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isLowResMobile = useMediaQuery({
    query: "(min-width: 300px) and (max-width: 600px)",
  });
  const isLowResDesktop = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });

  const slicedImages = images?.slice(0, isMobile ? 1 : 4) || [];
  if (slicedImages.length === 0) {
    return <></>;
  }

  const mobileSizes = {
    wide: {
      resolution: 800,
    },
    narrow: {
      resolution: 600,
    },
  };
  const desktopMainImageSizes = {
    wide: {
      resolution: 800,
    },
    narrow: {
      resolution: 600,
    },
  };
  const desktopThumbnailImageSizes = {
    wide: {
      resolution: 480,
    },
    narrow: {
      resolution: 340,
    },
  };

  return (
    <>
      {slicedImages.map((image, index) => (
        <Helmet key={image.url}>
          {isMobile ? (
            <>
              <link
                key={image.url}
                rel="preload"
                as="image"
                href={image.url.replace(
                  "{resize}",
                  `w${
                    isLowResMobile
                      ? mobileSizes.narrow.resolution
                      : mobileSizes.wide.resolution
                  }`,
                )}
              />
            </>
          ) : (
            <>
              <link
                key={image.url}
                rel="preload"
                as="image"
                href={image.url.replace(
                  "{resize}",
                  `w${
                    index === 0
                      ? isLowResDesktop
                        ? desktopMainImageSizes.narrow.resolution
                        : desktopMainImageSizes.wide.resolution
                      : isLowResDesktop
                      ? desktopThumbnailImageSizes.narrow.resolution
                      : desktopThumbnailImageSizes.wide.resolution
                  }`,
                )}
              />
            </>
          )}
        </Helmet>
      ))}
    </>
  );
};
