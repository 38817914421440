import {
  isMeetingATOnlineFinanceApplicationCriteria,
  isMeetingACOnlineFinanceApplicationCriteria,
} from "@at/finance-utils/lib/online-finance-application-utils/online-finance-application-utils";
import { Channel } from "@at/sauron-platform-core/lib/types";

const filterProducts = (product: string | null): product is string =>
  typeof product === "string";

export const meetsATOnlineFinanceApplicationRequirements = (
  products: ReadonlyArray<string | null> | null | undefined,
  condition: string | null | undefined,
  channel: Channel,
) => {
  const dealerProducts: string[] = products?.filter(filterProducts) || [];

  return !!(
    condition &&
    isMeetingATOnlineFinanceApplicationCriteria(
      channel,
      dealerProducts,
      condition,
    )
  );
};

export const meetsACOnlineFinanceApplicationRequirements = (
  products: ReadonlyArray<string | null> | null | undefined,
  condition: string | null | undefined,
  channel: Channel,
) => {
  const dealerProducts: string[] = products?.filter(filterProducts) || [];

  return !!(
    condition &&
    isMeetingACOnlineFinanceApplicationCriteria(
      channel,
      dealerProducts,
      condition,
    )
  );
};
