import { isValidLeaseOptionString } from "@at/leasing-utils/lib/leasing-utils/lease-option-utils";
import { Channel } from "@at/sauron-platform-core";
import queryParser from "qs";

import { searchInitialState } from "./search.reducer";
import {
  DEFAULT_ADVERTISING_LOCATIONS,
  DEFAULT_LEASING_OPTION,
  PageState,
  LeaseOfferType,
} from "./state";
import {
  AdvertisingLocation,
  LeaseContractType,
} from "../../../__generated__/graphql-types";

import { ELeasingBusinessType } from "../../../contexts/leasing/types/leasing.types";

export enum Condition {
  NEARLY_NEW = "NEARLY_NEW",
  NEW = "NEW",
  USED = "USED",
  VIRTUAL = "VIRTUAL",
}

export const safeToArray = (value: string | string[]): string[] => {
  return Array.isArray(value) ? value : [value];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasKey(obj: object, key: keyof any): key is keyof object {
  return key in obj;
}

export const getLeaseOfferTypeValues = (
  values?: string[],
): LeaseOfferType[] | null => {
  const types: LeaseOfferType[] = [];

  for (const value of values || []) {
    if (value != null && hasKey(LeaseOfferType, value)) {
      types.push(LeaseOfferType[value]);
    }
  }

  return types.length > 0 ? types : null;
};

export const getAdvertisingLocations = (
  values?: string[],
): AdvertisingLocation[] | null => {
  const types: AdvertisingLocation[] = [];

  for (const value of values || []) {
    if (value != null && hasKey(AdvertisingLocation, value)) {
      types.push(AdvertisingLocation[value]);
    }
  }

  return types.length > 0 ? types : null;
};

export const getConditions = (
  values: string[] | undefined,
): Condition[] | null => {
  const types: Condition[] = [];

  for (const value of values || []) {
    if (value != null && hasKey(Condition, value)) {
      types.push(Condition[value]);
    }
  }

  return types.length > 0 ? types : null;
};

export const urlEncodeValue = (value?: string | null): string | undefined => {
  return value == null ? undefined : encodeURIComponent(value);
};

interface params {
  [key: string]: (string | null) | undefined;
}

export const buildStateFromRequest = (
  search: string,
  page = "SEARCH",
  channel = Channel.cars,
): PageState => {
  const queryParams = (
    search ? queryParser.parse(search, { ignoreQueryPrefix: true }) : undefined
  ) as params;

  const defaultContractType =
    channel === Channel.cars
      ? LeaseContractType.personal
      : LeaseContractType.business;

  const defaultBusinessType = ELeasingBusinessType.DEFAULT;

  let stateFromRequest: PageState = {
    ...searchInitialState,
    leaseContractType: defaultContractType,
    page: page,
  };

  if (queryParams) {
    if (
      queryParams.leaseOption &&
      !isValidLeaseOptionString(queryParams.leaseOption)
    ) {
      queryParams.leaseOption = DEFAULT_LEASING_OPTION;
    } else if (
      queryParams.lease_option &&
      !isValidLeaseOptionString(queryParams.lease_option)
    ) {
      queryParams.lease_option = DEFAULT_LEASING_OPTION;
    }

    const getContractTypeFromQueryParams = (
      leaseType: string | null | undefined,
    ): LeaseContractType => {
      const contractTypeToLowerCase = leaseType?.toLowerCase();
      if (
        contractTypeToLowerCase &&
        contractTypeToLowerCase in LeaseContractType
      )
        return contractTypeToLowerCase as LeaseContractType;

      return defaultContractType;
    };

    const getBusinessTypeFromQueryParams = (
      businessType: string | null | undefined,
    ): ELeasingBusinessType => {
      switch (businessType) {
        case ELeasingBusinessType.SOLE_TRADER: {
          return ELeasingBusinessType.SOLE_TRADER;
        }
        case ELeasingBusinessType.LIMITED_LIABILITY_PARTNERSHIP: {
          return ELeasingBusinessType.LIMITED_LIABILITY_PARTNERSHIP;
        }
        case ELeasingBusinessType.LIMITED_COMPANY: {
          return ELeasingBusinessType.LIMITED_COMPANY;
        }
        default: {
          return defaultBusinessType;
        }
      }
    };

    stateFromRequest = {
      ...stateFromRequest,
      leaseContractType: getContractTypeFromQueryParams(
        queryParams.leaseContractType,
      ),
      selectedBusinessType: getBusinessTypeFromQueryParams(
        queryParams.businessType,
      ),
      make: queryParams.make ? [queryParams.make] : undefined,
      model: queryParams.model ? [queryParams.model] : undefined,
      bodyType: queryParams.bodyType
        ? [queryParams.bodyType]
        : queryParams["body-type"]
        ? [queryParams["body-type"]]
        : undefined,
      colour: queryParams.colour ? [queryParams.colour] : undefined,
      fuelType: queryParams.fuelType
        ? [queryParams.fuelType]
        : queryParams["fuel-type"]
        ? [queryParams["fuel-type"]]
        : undefined,
      minMonthlyPrice: queryParams.minMonthlyPrice
        ? Number(queryParams.minMonthlyPrice)
        : undefined,
      maxMonthlyPrice: queryParams.maxMonthlyPrice
        ? Number(queryParams.maxMonthlyPrice)
        : queryParams.max_lease_monthly_price
        ? Number(queryParams.max_lease_monthly_price)
        : undefined,
      minEngineSize: queryParams.minEngineSize
        ? Number(queryParams.minEngineSize)
        : undefined,
      maxEngineSize: queryParams.maxEngineSize
        ? Number(queryParams.maxEngineSize)
        : undefined,
      transmission: queryParams.transmission
        ? [queryParams.transmission]
        : undefined,
      minSeats:
        queryParams.minSeats == null ? undefined : Number(queryParams.minSeats),
      maxSeats:
        queryParams.maxSeats == null ? undefined : Number(queryParams.maxSeats),
      leaseOfferTypes:
        queryParams.leaseOfferType == null
          ? queryParams.lease_offer_type == null
            ? null
            : getLeaseOfferTypeValues(safeToArray(queryParams.lease_offer_type))
          : getLeaseOfferTypeValues(safeToArray(queryParams.leaseOfferType)),
      leaseOption:
        queryParams.leaseOption ||
        queryParams.lease_option ||
        DEFAULT_LEASING_OPTION,
      pageNumber: (queryParams.pageNumber &&
        (((queryParams.pageNumber as unknown as number) > 0
          ? queryParams.pageNumber
          : 1) as unknown)) as number,
      showTermFilters: queryParams.showTermFilters
        ? queryParams.showTermFilters === "true"
          ? true
          : false
        : false,
      showSearchFilters: queryParams.showSearchFilters
        ? queryParams.showSearchFilters === "true"
          ? true
          : false
        : false,
      sort: queryParams.sort || "lease-best-value-asc",
      minBatteryRange:
        queryParams.minBatteryRange == null
          ? undefined
          : Number(queryParams.minBatteryRange),
      maxBatteryRange:
        queryParams.maxBatteryRange == null
          ? undefined
          : Number(queryParams.maxBatteryRange),
      minBatteryChargeTime:
        queryParams.minBatteryChargeTime == null
          ? undefined
          : Number(queryParams.minBatteryChargeTime),
      maxBatteryChargeTime:
        queryParams.maxBatteryChargeTime == null
          ? undefined
          : Number(queryParams.maxBatteryChargeTime),
      minBatteryQuickChargeTime:
        queryParams.minBatteryQuickChargeTime == null
          ? undefined
          : Number(queryParams.minBatteryQuickChargeTime),
      maxBatteryQuickChargeTime:
        queryParams.maxBatteryQuickChargeTime == null
          ? undefined
          : Number(queryParams.maxBatteryQuickChargeTime),
      advertisingLocations: queryParams.advertisingLocation
        ? getAdvertisingLocations(safeToArray(queryParams.advertisingLocation))
        : DEFAULT_ADVERTISING_LOCATIONS,
      condition: queryParams.condition
        ? getConditions(safeToArray(queryParams.condition))
        : undefined,
      aggregatedTrim: queryParams.aggregatedTrim as unknown as
        | (string | null)[]
        | null
        | undefined,
    };
  }

  return stateFromRequest;
};
