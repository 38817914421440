import { Link } from "@at/design-system-elements";
import { ThemeSelectors } from "@at/design-system-foundation";
import { BREAKPOINTS } from "@at/window-utils/lib/responsive-utils/match-media.util";
import styled, { css } from "styled-components";

import {
  isUserInLinkUnderlineTest,
  isUserInRedButtonTest,
} from "../../../../utils/ab-test/ab-test.util";
import { LinkUnderlineStyles } from "../../../../utils/red-button-test/red-button-test.utils";

const underlineLinks = isUserInRedButtonTest || isUserInLinkUnderlineTest;

export const PrintStyleSheet = css`
  @media print {
    display: none;
  }
`;

export const ButtonSpacing = styled.div<{ removeMargin: boolean }>`
  ${({ theme, removeMargin }) => css`
    margin: ${removeMargin ? "0" : `${ThemeSelectors.getGridUnit(theme)} 0`};
  `}
  ${PrintStyleSheet};
`;

export const NewVehicleMapCopyStyles = styled.p`
  margin: ${({ theme }) => ThemeSelectors.getGridUnit(theme)} 0;
`;

export const AdvertSellerVisitWebsiteTop = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.MEDIUM}px) {
    display: block;
  }
`;

export const Button = styled(Link)`
  ${({ theme }) => css`
    border: none;
    padding: ${ThemeSelectors.getGridUnit(theme)} 0 16px 0;
    text-align: left;

    ${underlineLinks && LinkUnderlineStyles};

    &:hover {
      cursor: pointer;
    }

    svg {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 ${ThemeSelectors.getGroupOneSpacing(theme)} 0 0;
      fill: currentColor;
      vertical-align: middle;

      &.visit-website {
        margin: 0 10px 0 0;
      }

      path {
        fill: currentColor;
      }
    }
    @media print {
      display: none;
    }
  `}
`;

export const MapContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
`;

export const MapIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 380px;
`;

export const MapLoadingSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  > .loader {
    height: 100%;
  }
`;

export const MapTabs = styled.div`
  display: block;

  &[hidden] {
    display: none;
  }
`;

export const MapTabsDetails = styled.div`
  margin: ${({ theme }) => `0 0 ${ThemeSelectors.getGridUnit(theme)} 0 `};
`;

export const MapTabsAddress = styled.p`
  ${({ theme }) => css`
    display: inline-block;
    margin: 0 calc(${ThemeSelectors.getGridUnit(theme)} * 0.75) 0 0;
    padding: ${ThemeSelectors.getGridUnit(theme)} 0;
    color: ${ThemeSelectors.getLegacyCopyLightColor(theme)};
  `}
`;

export const MapTab = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    height: 385px;
    background: ${ThemeSelectors.getLegacyLightGrey(theme)};

    &[hidden] {
      display: none;
    }

    @media screen and (min-width: ${BREAKPOINTS.MEDIUM}px) {
      height: 300px;
    }

    @media screen and (min-width: ${BREAKPOINTS.WIDE}px) {
      height: 400px;
    }
  `}
`;

export const MapTabsToggleIcon = styled.span<{ isExpanded: boolean }>`
  div {
    display: inline-flex;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-top: -1px;
    transform: ${({ isExpanded }) =>
      isExpanded ? "rotate(180deg)" : undefined};
    vertical-align: middle;
  }

  svg {
    width: 100%;
    height: 100%;
    margin: 0;
  }
`;

export const BreakpointSmall = styled.div`
  display: block;

  @media screen and (min-width: ${BREAKPOINTS.MEDIUM}px) {
    display: none;
  }
  ${PrintStyleSheet}
`;
export const Wrapper = styled.div`
  display: block;

  ${PrintStyleSheet}
`;

export const BreakpointMedium = styled.div`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.MEDIUM}px) {
    display: block;
  }
`;
