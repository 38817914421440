import { InMemoryCache, defaultDataIdFromObject } from "@apollo/client";

export const createCache = (): InMemoryCache => {
  return new InMemoryCache({
    typePolicies: {
      Specification: {
        merge: true,
      },
      Search: {
        merge: true,
      },
      Dealer: {
        merge: true,
      },
    },
    dataIdFromObject: (object) => {
      if ("leasingOffers" in object) {
        return defaultDataIdFromObject(object);
      }

      let postcode = "";
      if (
        window.location !== undefined &&
        window.location.search !== undefined
      ) {
        const params = new URLSearchParams(window.location.search);
        postcode =
          params.get("postcode")?.replace(/\s/g, "").toUpperCase() || "";
      }

      switch (object.__typename) {
        case "Advert": {
          return `Advert:${object.id}:${postcode}`;
        }
        case "CollectionLocation": {
          return `CollectionLocation:${object.id}:${postcode}`;
        }
        default: {
          return defaultDataIdFromObject(object);
        }
      }
    },
  });
};
