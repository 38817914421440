import { ComponentViewed100 } from "@at/track-core";

import { getCookie } from "../cookie.utils";

const COOKIE_NAME_PATTERN = new RegExp("^_sp_id\\.[a-f0-9]+");
const USER_ID_PATTERN = new RegExp("^(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})");
const SESSION_ID_PATTERN = new RegExp("(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})$");

export const getSnowplowCookieValues = (): {
  domainUserId: string | null;
  domainSessionId: string | null;
} => {
  let domainUserId = null;
  let domainSessionId = null;

  const cookies = getCookie();
  const values = Object.entries(cookies);
  const cookieMatch = values.find(([name]) => name.match(COOKIE_NAME_PATTERN));

  if (cookieMatch) {
    const matchUserId = cookieMatch[1]?.match(USER_ID_PATTERN);
    const matchSessionId = cookieMatch[1]?.match(SESSION_ID_PATTERN);

    domainUserId = (matchUserId || [])[0] || null;
    domainSessionId = (matchSessionId || [])[0] || null;
  }

  return {
    domainUserId,
    domainSessionId,
  };
};

export const getComponentViewedEntity = (componentNameLabel: string) => {
  const componentViewedEntity: ComponentViewed100 = {
    SCHEMA_KEY: "ComponentViewed100",
    component_name: componentNameLabel,
  };
  return componentViewedEntity;
};
