import { gql } from "@at/sauron-platform-graphql";

export const fPADataGql = gql`
  query FPADataQuery(
    $advertId: String!
    $numberOfImages: Int
    $searchOptions: SearchOptions
    $postcode: String
  ) {
    search {
      advert(advertId: $advertId, searchOptions: $searchOptions) {
        id
        stockItemId
        isAuction
        hoursUsed
        serviceHistory
        title
        excludePreviousOwners
        advertisedLocations
        dueAtSeller
        motExpiry
        motInsurance
        lastServiceOdometerReadingMiles
        lastServiceDate
        warrantyMonthsOnPurchase
        twelveMonthsMotIncluded
        preReg
        heading {
          title
          subtitle
        }
        attentionGrabber
        rrp
        price
        priceGBX
        priceExcludingFees
        priceExcludingFeesGBX
        suppliedPrice
        suppliedPriceGBX
        priceOnApplication
        plusVatIndicated
        vatStatus
        saving
        noAdminFees
        adminFee
        adminFeeInfoDescription
        dateOfRegistration
        homeDeliveryRegionCodes
        priceExcludingVat
        vatToBePaidUpfront
        capabilities {
          marketExtensionHomeDelivery {
            enabled
          }
          marketExtensionClickAndCollect {
            enabled
          }
          marketExtensionCentrallyHeld {
            enabled
          }
          marketExtensionOem {
            enabled
          }
          digitalRetailing {
            enabled
          }
        }
        registration
        generation {
          generationId
          name
          review {
            expertReviewSummary {
              rating
              reviewUrl
            }
          }
        }
        hasShowroomProductCode
        isPartExAvailable
        isFinanceAvailable
        isFinanceFullApplicationAvailable
        financeProvider
        financeDefaults {
          term
          mileage
          depositAmount
        }
        hasFinanceInformation
        retailerId
        privateAdvertiser {
          contact {
            protectedNumber
            email
          }
          location {
            town
            county
            postcode
          }
          tola
        }
        advertiserSegment
        dealer {
          dealerId
          description
          distance
          stockLevels {
            atStockCounts {
              car
              van
            }
          }
          assignedNumber {
            number
          }
          atAwards {
            year
            category
          }
          branding {
            accreditations {
              name
            }
            brands {
              name
              imageUrl
            }
          }
          capabilities {
            instantMessagingChat {
              enabled
              provider
            }
            instantMessagingText {
              enabled
              provider
              overrideSmsNumber
            }
          }
          reviews {
            numberOfReviews
            overallReviewRating
          }
          location {
            addressOne
            addressTwo
            town
            county
            postcode
            latLong
          }
          marketing {
            profile
            strapline
            brandingBanner {
              href
            }
          }
          media {
            email
            dealerWebsite {
              href
            }
            phoneNumber1
            phoneNumber2
            protectedNumber
          }
          name
          servicesOffered {
            sellerPromise {
              monthlyWarranty
              daysMoneyBackGuarantee
              moneyBackRemoteOnly
            }
            services
            products
            safeSelling {
              bulletPoints
              paragraphs
            }
            videoWalkAround {
              bulletPoints
              paragraphs
            }
            nccApproved
            isHomeDeliveryProductEnabled
            isPartExAvailable
            hasSafeSelling
            hasHomeDelivery
            hasVideoWalkAround
            additionalLinks {
              title
              href
            }
          }
        }
        video {
          url
          preview
        }
        spin {
          url
          preview
        }
        imageList(limit: $numberOfImages) {
          nextCursor
          size
          images {
            url
            templated
            autotraderAllocated
            classificationTags {
              label
              category
            }
          }
        }
        priceIndicatorRating
        priceIndicatorRatingLabel
        priceDeviation
        mileageDeviation
        mileage {
          mileage
          unit
        }
        plate
        year
        vehicleCheckId
        vehicleCheckStatus
        vehicleCheckSummary {
          type
          title
          performed
          writeOffCategory
          checks {
            key
            failed
            advisory
            critical
            warning
          }
        }
        sellerName
        sellerType
        sellerProducts
        sellerLocation
        sellerLocationDistance {
          unit
          value
        }
        sellerContact {
          phoneNumberOne
          phoneNumberTwo
          protectedNumber
          byEmail
        }
        description
        colour
        manufacturerApproved
        insuranceWriteOffCategory
        owners
        keys
        vehicleCondition {
          tyreCondition
          interiorCondition
          bodyCondition
        }
        specification {
          driverPosition
          isCrossover
          operatingType
          emissionClass
          co2Emissions {
            co2Emission
            unit
          }
          topSpeed {
            topSpeed
          }
          minimumKerbWeight {
            weight
            unit
          }
          endLayout
          trailerAxleNumber
          bedroomLayout
          grossVehicleWeight {
            weight
            unit
          }
          capacityWeight {
            weight
            unit
          }
          liftingCapacity {
            weight
            unit
          }
          operatingWidth {
            width
            unit
          }
          maxReach {
            length
            unit
          }
          wheelbase
          berth
          bedrooms
          engine {
            power {
              enginePower
              unit
            }
            sizeLitres
            sizeCC
            manufacturerEngineSize
          }
          exteriorWidth {
            width
            unit
          }
          exteriorLength {
            length
            unit
          }
          exteriorHeight {
            height
            unit
          }
          capacityWidth {
            width
            unit
          }
          capacityLength {
            length
            unit
          }
          capacityHeight {
            height
            unit
          }
          seats
          axleConfig
          ulezCompliant
          doors
          bodyType
          cabType
          rawBodyType
          fuel
          transmission
          style
          subStyle
          make
          model
          trim
          optionalFeatures {
            description
            category
          }
          standardFeatures {
            description
            category
          }
          features {
            category
            features {
              description
              type
            }
          }
          driverPosition
          battery {
            capacity {
              capacity
              unit
            }
            usableCapacity {
              capacity
              unit
            }
            range {
              range
              unit
            }
            charging {
              quickChargeTime
              chargeTime
            }
          }
          techData {
            co2Emissions
            fuelConsumptionCombined
            fuelConsumptionExtraUrban
            fuelConsumptionUrban
            insuranceGroup
            minimumKerbWeight
            zeroToSixtyMph
            zeroToSixtyTwoMph
            cylinders
            valves
            enginePower
            topSpeed
            engineTorque
            vehicleHeight
            vehicleLength
            vehicleWidth
            wheelbase
            fuelTankCapacity
            grossVehicleWeight
            luggageCapacitySeatsDown
            bootspaceSeatsUp
            minimumKerbWeight
            vehicleWidthInclMirrors
            maxLoadingWeight
            standardFeatures {
              description
              category
            }
            chargingData {
              fastestChargingPower
              fastestChargingDuration
              chargers {
                description
                fullCharge {
                  duration
                  endBatteryPercentage
                }
                topUp {
                  milesRange
                  duration
                }
                chargerLocation
                milesRangePerHourChargeTime
              }
            }
          }
          annualTax {
            standardRate
          }
          oemDrivetrain
          bikeLicenceType
          derivativeId
          frameSizeCM
          frameMaterial
          frameStyle
          suspensionType
          gearShifter
          brakeType
          motorMake
          chargeTimeMinutes
          numberOfGears
          tyreDiameterInches
          driveTrain
          torque {
            torque
            unit
          }
          range {
            totalRange
            unit
          }
          interiorDescription
          exteriorDescription
        }
        stockType
        condition
        finance {
          monthlyPayment
          representativeApr
        }
        locationArea(postcode: $postcode) {
          code
          region
          areaOfInterest {
            postCode
            manufacturerCodes
          }
        }
        reservation {
          status
          eligibility
          feeCurrency
          feeInFractionalUnits
        }
      }
    }
  }
`;

export const fPASimpleDataGql = gql`
  query FPASimpleDataQuery(
    $advertId: String!
    $searchOptions: SearchOptions
    $postcode: String!
  ) {
    addresses(postcode: $postcode) {
      postcode
    }
    search {
      advert(advertId: $advertId, searchOptions: $searchOptions) {
        id
        deliversToMyPostcode
      }
      __typename
    }
  }
`;
