import React from "react";

import { useAppContext } from "@at/sauron-platform-core";
import { withLayout } from "@at/sauron-platform-core/lib/layout/layout.hoc";

import { ATFooter } from "../../../components/at-footer/at-footer";
import { ATHeader } from "../../../components/at-header/at-header";
import { withUsedProductPageData } from "../../../connected-components/hoc/product-page.hoc";
import { withQueryClient } from "../../../connected-components/hoc/query-client.hoc";
import { useCreateOrder } from "../../../utils/hooks/use-create-order/use-create-order";
import {
  DigitalRetailingPage,
  ProductPage,
  SkeletonProductPage,
} from "../loadables";

const ProductOrDigitalRetailingPage = () => {
  const layoutOptions = {
    HeaderComponent: ATHeader,
    FooterComponent: ATFooter,
    mainClassName: "used-fpa",
  };

  const { ssrMode } = useAppContext();

  if (ssrMode) {
    const SkeletonPage = withLayout(SkeletonProductPage, layoutOptions);
    return <SkeletonPage />;
  }

  const PageComponent = () => {
    const { hasDigitalRetailingCapability } = useCreateOrder();
    return hasDigitalRetailingCapability ? (
      <DigitalRetailingPage />
    ) : (
      <ProductPage />
    );
  };

  let WrappedPage = PageComponent;
  WrappedPage = withUsedProductPageData(WrappedPage);
  WrappedPage = withQueryClient(WrappedPage);
  const LayoutComponent = withLayout(WrappedPage, layoutOptions);

  return <LayoutComponent />;
};

export default ProductOrDigitalRetailingPage;
