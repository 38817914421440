import React from "react";

import { useLocation } from "react-router-dom";

import { isAdvertisingLocation, getChannelName } from "@at/formatter-utils";
import { withPageState } from "@at/sauron-platform-core";
import { withLayout } from "@at/sauron-platform-core/lib/layout/layout.hoc";
import { parse } from "query-string";

import { Channel } from "../../../__generated__/fed-graphql-types";
import { layoutOptions } from "../../../utils/page-wrapper/page-wrapper-utils";
import { ContactDealerError } from "../loadables";

const ContactDealerErrorPage = () => {
  const { search } = useLocation();
  const queryParam = parse(search || "")["advertising-location"];

  const channel = isAdvertisingLocation(queryParam)
    ? getChannelName(queryParam)
    : Channel.cars;

  const WrappedPage = withPageState(
    withLayout(ContactDealerError, {
      ...layoutOptions,
      mainClassName: "search-app",
    }),
    { channel: channel },
  );

  return <WrappedPage />;
};

export default ContactDealerErrorPage;
