import React from "react";

import { Link } from "@at/design-system-elements";
import styled from "styled-components";

import { useTracking } from "../../../../../utils/tracking/use-tracking";

interface Props {
  url: string;
  label: string;
}

const Wrapper = styled.div`
  border: none;
`;

const SocialLink: React.FC<Props> = (props) => {
  const { url, children, label } = props;
  const { trackEvent } = useTracking();
  return (
    <Wrapper>
      <Link
        href={url}
        attributes={{
          target: "_blank",
          rel: "nofollow noopener noreferrer external",
          "data-testid": `${label}-share-link`,
        }}
        trackingCallback={() => {
          trackEvent({ trackingLabel: label });
        }}
      >
        {children}
      </Link>
    </Wrapper>
  );
};

export default SocialLink;
