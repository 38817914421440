/* eslint-disable no-console */
import { getChannelSingular } from "@at/formatter-utils/lib/channel-utils/channel.util";
import { capitalsToTitleCase } from "@at/formatter-utils/lib/string-utils/string.utils";
import { Channel, Environment, Platform } from "@at/sauron-platform-core";
import { stringifyUrl } from "query-string";

import { VatStatus } from "../../__generated__/graphql-types";
import { FPAFinanceQuery_financeCalculator_FinanceCalculator_getRepresentativeExamples_RepresentativeExampleResponse_cheapestQuote_FinanceQuote } from "../../connected-components/hoc/query/__generated__/fpa-finance.query";
import {
  FPADataQuery_search_Search_advert_Advert as Advert,
  FPADataQuery_search_Search_advert_Advert_specification_Specification as AdvertSpecification,
} from "../../connected-components/hoc/query/__generated__/fpa-page-data.query";
import {
  isUserInUsedCarFinanceCalculatorControl,
  isUserInUsedCarFinanceCalculatorTest,
} from "../ab-test/ab-test.util";
import { getChannelPriceLabel } from "../channel/channel-utils";
import { SellerType } from "../seller/seller.utils";
import { getQueryParamValueFromSearch } from "../uri/uri-utils";

export const getPartnerFinanceZutoUrl = (environment: Environment) =>
  environment === Environment.prod
    ? "https://autotrader.zuto.com/apply"
    : "https://qa-autotrader.zuto.com/apply";

export const ZUTO_DISPLAY_NAME = "Zuto";

interface AdvertSpecificationValidFullFinanceApplication
  extends AdvertSpecification {
  make: string;
}

interface AdvertValidFullFinanceApplication extends Advert {
  isFinanceFullApplicationAvailable: true;
  specification: AdvertSpecificationValidFullFinanceApplication;
  sellerName: string;
}

export const isValidForFullFinanceApplication = (
  advert: Advert,
): advert is AdvertValidFullFinanceApplication => {
  if (
    !advert.isFinanceFullApplicationAvailable ||
    !advert?.specification?.make ||
    !advert.sellerName
  ) {
    return false;
  }

  return true;
};

export const isTpfOrUsedVanOrUsedCrossover = (
  condition: string | null | undefined,
  channel: Channel | null,
  isCrossover: boolean,
): boolean => {
  return (
    channel === Channel.trucks ||
    channel === Channel.farm ||
    channel === Channel.plant ||
    ((isCrossover || channel === Channel.vans) && condition !== "New")
  );
};

export const noVatStatusTextForTpfOrUsedVan = (
  isChannelTpfOrUsedVan: boolean,
  vatStatus: VatStatus | null | undefined,
): string => {
  return isChannelTpfOrUsedVan && vatStatus === VatStatus.NO_VAT
    ? " (No VAT)"
    : "";
};

const generateVatStatus = (
  vatStatus: VatStatus | null | undefined,
  plusVatIndicated: boolean,
  isChannelTpfOrVan: boolean,
  isUsedVanOrCrossover: boolean,
): string => {
  // set to false for used vans and crossovers
  plusVatIndicated = !isUsedVanOrCrossover && plusVatIndicated;

  return plusVatIndicated
    ? " (inc VAT)"
    : noVatStatusTextForTpfOrUsedVan(isChannelTpfOrVan, vatStatus);
};

export const generateVanLabel = (
  vatStatus: VatStatus | null | undefined,
): string => {
  return vatStatus === VatStatus.INC_VAT || vatStatus === VatStatus.PLUS_VAT
    ? "Price of van (excl. VAT)"
    : "Van price";
};

export const getFinanceRepresentativeMessage = (
  financeQuote: FPAFinanceQuery_financeCalculator_FinanceCalculator_getRepresentativeExamples_RepresentativeExampleResponse_cheapestQuote_FinanceQuote | null,
  plusVatIndicated: boolean,
  condition: string | null | undefined,
  vatStatus: VatStatus | null | undefined,
  channel: Channel,
  isCrossover: boolean,
): string => {
  const array: string[] = [];

  if (!financeQuote) {
    return "";
  }

  if (!!financeQuote.monthlyPayment) {
    array.push(`Monthly payments ${financeQuote.monthlyPayment}`);
  }

  if (!!financeQuote.duration) {
    array.push(`Term ${financeQuote.duration} months`);
  }

  if (!!financeQuote.contractLength) {
    array.push(`Contract length ${financeQuote.contractLength} months`);
  }

  if (financeQuote.onRoadPrice) {
    const isChannelTpfOrVanOrCrossover = isTpfOrUsedVanOrUsedCrossover(
      condition,
      channel,
      isCrossover,
    );
    const isUsedVanOrCrossover =
      (isCrossover || channel === Channel.vans) && condition !== "New";
    const getPriceLabel = () =>
      getChannelPriceLabel(channel, undefined, isCrossover);
    array.push(
      [
        getPriceLabel() + " ",
        financeQuote.onRoadPrice,
        generateVatStatus(
          vatStatus,
          plusVatIndicated,
          isChannelTpfOrVanOrCrossover,
          isUsedVanOrCrossover,
        ),
      ].join(""),
    );
  }

  if (!!financeQuote.customerDeposit) {
    array.push(`Cash deposit ${financeQuote.customerDeposit}`);
  }

  if (!!financeQuote.totalCredit) {
    array.push(`Total amount of credit ${financeQuote.totalCredit}`);
  }

  if (!!financeQuote.totalAmountPayable) {
    array.push(`Total amount payable ${financeQuote.totalAmountPayable}`);
  }

  if (!!financeQuote.representativeAPR) {
    array.push(`Representative APR ${financeQuote.representativeAPR}`);
  }

  if (!!financeQuote.totalChargesPayable) {
    array.push(`Total charges payable ${financeQuote.totalChargesPayable}`);
  }

  if (!!financeQuote.fixedRateInterest) {
    array.push(`Fixed rate of interest pa ${financeQuote.fixedRateInterest}`);
  }

  if (financeQuote.depositContributions) {
    for (const contribution of financeQuote.depositContributions) {
      const { name, amount } = contribution || {};
      !!name && !!amount && array.push(`${name} ${amount}`);
      continue;
    }
  }

  if (financeQuote.finalPayment && financeQuote.quoteType === "PCP") {
    array.push(`Optional final payment ${financeQuote.finalPayment}`);
  }

  if (!!financeQuote.adminFee) {
    array.push(`Admin fee ${financeQuote.adminFee}`);
  }

  if (!!financeQuote.optionToPurchaseFee) {
    const label =
      financeQuote.quoteTypeDisplayName === "CS"
        ? "Title transfer fee"
        : "Option to purchase fee";
    array.push(`${label} ${financeQuote.optionToPurchaseFee}`);
  }

  if (!!financeQuote.excessMileageRate) {
    array.push(`Excess mileage fee ${financeQuote.excessMileageRate}`);
  }

  if (array.length === 0) {
    return "";
  }

  const representativeMessage: string = array.join(", ").concat(".");

  return representativeMessage;
};

export const getPartnerFinanceCampaignCode = ({
  channel,
  hasPartnerFinance,
  isTola,
  platform,
  sellerType,
}: {
  channel: Channel;
  hasPartnerFinance: boolean;
  isTola: boolean;
  platform: Platform;
  sellerType: SellerType;
}): string => {
  let campaignCode = "";

  campaignCode += platform === Platform.mobile ? "Mob" : "Desk";
  campaignCode += capitalsToTitleCase(getChannelSingular(channel));
  campaignCode +=
    sellerType === SellerType.PRIVATE
      ? isTola
        ? "Paygo"
        : "Private"
      : "Trade";
  campaignCode +=
    hasPartnerFinance && channel === Channel.cars ? "FpaDB" : "Fpa";

  return campaignCode;
};

export const getPartnerFinanceUrl = (
  advert: Advert,
  channel: Channel,
  platform: Platform,
  environment: Environment,
) => {
  const partnerUrl = getPartnerFinanceZutoUrl(environment);

  if (!partnerUrl) {
    return null;
  }

  const hasPartnerFinance = !!advert.sellerProducts?.includes("PFIN");
  const isTola = !!advert.privateAdvertiser?.tola;
  const sellerType =
    advert.sellerType === SellerType.PRIVATE
      ? SellerType.PRIVATE
      : SellerType.TRADE;

  return stringifyUrl({
    url: partnerUrl,
    query: {
      device: "mobile-web", // Zuto don't use device except for native app journeys
      utm_source: "autotrader",
      utm_medium: "affiliate",
      utm_campaign: getPartnerFinanceCampaignCode({
        channel,
        hasPartnerFinance,
        isTola,
        platform,
        sellerType,
      }),
      lead_ref: isUserInUsedCarFinanceCalculatorControl()
        ? "TEST1"
        : isUserInUsedCarFinanceCalculatorTest()
        ? "TEST2"
        : undefined,
      advertId: advert.id,
    },
  });
};

export const hasNotArrivedFromFinanceSearchOrCalculatorPage = (
  queryParameters: string,
  channel: Channel,
): boolean => {
  const isSearchPageDefaultFinanceParamsNull =
    getQueryParamValueFromSearch("deposit", queryParameters) === null &&
    getQueryParamValueFromSearch("term", queryParameters) === null &&
    getQueryParamValueFromSearch("mileage", queryParameters) === null;

  const isFinanceCalcPageFinanceParamsNull =
    getQueryParamValueFromSearch("calc-deposit", queryParameters) === null &&
    getQueryParamValueFromSearch("calc-term", queryParameters) === null &&
    getQueryParamValueFromSearch("calc-mileage", queryParameters) === null;

  return (
    isSearchPageDefaultFinanceParamsNull &&
    isFinanceCalcPageFinanceParamsNull &&
    channel === Channel.cars
  );
};

export const get10PercentOfValue = (
  value: number | null | undefined,
): string | null => {
  return (value && Math.round(0.1 * value).toString()) || null;
};

export const getRepAprExample = (carValue: number): string | null => {
  if (carValue <= 0) return null;

  const bandings = [
    { max: 6500, percentage: "17.9%" },
    { max: 13_000, percentage: "17.9%" },
    { max: 19_500, percentage: "17.4%" },
    { max: 26_000, percentage: "16.8%" },
    { max: Number.POSITIVE_INFINITY, percentage: "15.7%" },
  ];

  for (const range of bandings) {
    if (carValue < range.max) {
      return range.percentage;
    }
  }

  return null;
};
