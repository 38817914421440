import { Channel } from "@at/sauron-platform-core/lib/types";

export const retailerHasSellerPromise = (
  channel: Channel,
  condition: string | null | undefined,
) => {
  const sellerPromiseChannel =
    channel === Channel.cars || channel === Channel.vans;

  return sellerPromiseChannel && condition !== "New";
};
