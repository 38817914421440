import { SearchJourney100 } from "@at/track-core";

export function isValidSearchContext(
  value: string,
): value is SearchJourney100["search_context"] {
  const validContexts: SearchJourney100["search_context"][] = [
    "DEALER_STOCK_LIST_JOURNEY",
    "FEATURED_LISTING_JOURNEY",
    "HOME_SCREEN_JOURNEY",
    "LEASE_LISTING_JOURNEY",
    "NATURAL_SEARCH_JOURNEY",
    "PROMOTED_LISTING_JOURNEY",
    "SOURCING",
    "YOU_MAY_ALSO_LIKE_JOURNEY",
  ];

  return validContexts.includes(value as SearchJourney100["search_context"]);
}
export const defaultSearchJourneyEntity: SearchJourney100 = {
  SCHEMA_KEY: "SearchJourney100",
  search_context: "NATURAL_SEARCH_JOURNEY",
};
